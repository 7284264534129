import styled from 'styled-components'

// Library Components
import { FormGroup } from 'reactstrap'

export const FormSelectWrapper = styled.div``

export const FormGroupWrapper = styled(FormGroup)`
  position: relative;

  // Overrides
  &.form-group {
    margin: 0px;
  }

  // TODO: Use theme functions
  &:after {
    content: '';

    position: absolute;
    top: 42%;
    right: 15px;

    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;

    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #000;
  }
`

export const FormLabel = styled.label``

export const FormSelectInput = styled.select`
  // Overrides
  &.form-control {
    box-shadow: none;

    // TODO: Use theme color
    border: 1px solid #ced4da;
  }

  height: fit-content;
  width: 100%;

  padding: 0.7rem 0.75rem;

  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  box-shadow: none;

  // TODO: Use theme color
  border: 1px solid #ced4da;
  background-color: #fff;
`

export const FormSelectOption = styled.option`
  // TODO: Use theme
  color: #495057;
`

export const FormErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: red;
  font-size: 14px;
`
