import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Styled Elements
import {
  DateRangePickerWrapper,
  DateRangeHolder,
  DateRangeTextWrapper,
  DateRangeText,
} from './DateRangePicker.elements'

const DateRangePickerSet = (props) => {
  // Destructure
  const { opens, onApply } = props

  // States
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    endDate: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  })

  // Functions
  const handleApplyDatePicker = (e, picker) => {
    setDateRange({
      startDate: picker.startDate.format(DASHED_DATE_FORMAT),
      endDate: picker.endDate.format(DASHED_DATE_FORMAT),
    })

    onApply({
      start: picker.startDate.format(DASHED_DATE_FORMAT),
      end: picker.endDate.format(DASHED_DATE_FORMAT),
    })
  }

  return (
    <DateRangePickerWrapper>
      <DateRangePicker
        onApply={handleApplyDatePicker}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        locale={{ format: DASHED_DATE_FORMAT }}
        // maxDate={moment(moment(new Date()), DASHED_DATE_FORMAT).add(1, 'days')}
        initialSettings={{ maxDate: moment(moment(new Date()), DASHED_DATE_FORMAT).add(1, 'days') }}
        opens={opens}
      >
        <DateRangeHolder>
          <DateRangeTextWrapper>
            <DateRangeText>From:</DateRangeText>
            <DateRangeText>{dateRange.startDate}</DateRangeText>
          </DateRangeTextWrapper>
          <DateRangeTextWrapper>
            <DateRangeText>To:</DateRangeText>
            <DateRangeText>{dateRange.endDate}</DateRangeText>
          </DateRangeTextWrapper>
        </DateRangeHolder>
      </DateRangePicker>
    </DateRangePickerWrapper>
  )
}

// Default Props
DateRangePickerSet.defaultProps = {
  opens: 'center',
  onApply: () => { },
}

// Proptypes Validation
DateRangePickerSet.propTypes = {
  opens: PropTypes.string,
  onApply: PropTypes.func,
}

export default DateRangePickerSet
