import styled from 'styled-components'

export const BadgeTextWrapper = styled.div`
  position: relative;
  height: 25.57px;
  margin-left: 1px;
  margin-right: 1px;
  width: 35px;
  border-radius: 3px;
  font-weight: inherit;
  font-size: inherit;

  // Conditional Properties
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`};
`

export const BadgeTextBackground = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0.1;

  // Conditional Properties
  ${({ color }) => color && `background-color: ${color};`}
`

export const BadgeTextValue = styled.div`
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  right: 0;

  // Conditional Properties
  ${({ color }) => color && `color: ${color};`}
`
