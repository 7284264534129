export const BASE_URL = 'https://apitest2.nodestash.com'

export const AUTH_ENDPOINTS = {
  LOGIN: '/api/login',
  RESET_PASSWORD: '/api/ui/users/setpassword',
}

export const WHITELISTING_ENDPOINTS = {
  PAGED_TABLE_DATA: '/api/whitelist/paged-table',
  RISK_BUCKET: '/api/whitelist/risk-bucket',
  ADDRESS_HISTORY: '/api/whitelist/address-hist',
  ADDRESS_COUNT: '/api/whitelist/count-address',
  ASSIGN_ADDRESS: '/api/whitelist/assign-address',
  SEARCH_ADDRESS: '/api/whitelist/search-address?',
  REMOVE_ADDRESS: '/api/whitelist/remove-address',
  GET_ADDRESS_REPORT: '/api/reporting/address',
  GET_REPORT: '/api/reporting/address'
}

export const MONITORING_ENDPOINTS = {
  PAGED_TABLE_DATA: '/api/monitoring/paged-table',
  RISK_BUCKET: '/api/monitoring/risk-bucket',
  TRANSACTION_HISTORY: '/api/monitoring/transaction-hist',
  TRANSACTION_COUNT: '/api/monitoring/count-transaction',
  ASSIGN_TRANSACTION: '/api/monitoring/assign-transaction',
  SEARCH_TRANSACTION: '/api/monitoring/search-transaction?',
  REMOVE_TRANSACTION: '/api/monitoring/remove-transaction',
  GET_TRANSACTION_REPORT: '/api/reporting/transaction',
}

export const CASES_ENDPOINTS = {
  // Dashboard
  DASHBOARD_TOTAL_CASES: '/api/case/dashbaord-case-total',
  DASHBOARD_CASE_ADDRESSES: '/api/case/dashbaord-case-addresses',
  DASHBOARD_CASE_TRANSACTIONS: '/api/case/dashbaord-case-transactions',

  // Create Case
  CREATE_CASE: '/api/case/create-case',

  // Read
  READ_ALL_CASES: '/api/case/read-all-case',
  READ_CASE_ACTIVITIES: '/api/case/read-case-activities',
  READ_CASE_CUSTOMER: '/api/case/read-case-customer',
  READ_CASE_ADDRESSES: '/api/case/read-address-case',
  READ_CASE_DETAILS: '/api/case/read-detail-case',
  READ_CASE_TRANSACTIONS: '/api/case/read-transaction-case',

  // Search
  AUTOCOMPLETE_CUSTOMER_SEARCH: '/api/case/search-customer',
  CUSTOMER_SEARCH: '/api/case/read-case-customer',

  UPDATE_CASE: '/api/case/update-case',
  GET_ALL_COUNTRIES: '/api/case/get-all-countries',
  INVESTIGATION: '/api/investigation/investigation',
}

export const PROFILE_ENDPOINTS = {
  READ_PROFILE: '/api/setting/read-userprofile',
  UPDATE_PROFILE: '/api/setting/update-userprofile',
}

export const EXTERNAL_URLS = {
  MAIN_SITE: 'https://www.ospree.io/',
  GET_STARTED: 'https://www.ospree.io/get-demo',
  FEEDBACK: 'https://ospree.io/contact',
  PRIVACY_POLICY: 'https://www.ospree.io/privacy-policy',
}

export const BATCH_UPLOAD_UPLOADS = {
  CASES: '/api/case/batch-create-cases',
  ADDRESSES: '/api/case/batch-address',
  TRANSACTIONS: '/api/case/batch-transaction',
}
