import styled from 'styled-components'

export const TableDataWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TableDataItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-weight: 100;
  font-size: 15px;
  font-family: Roboto;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`
