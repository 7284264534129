import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

// Hooks
import { useUpdateProfile } from 'core/hooks/api'

// Styled Elements
import {
  FormInputGroupWrapper,
  FormInputGroupItem,
  ProfilePersonalInformationFormWrapper,
  FormInputGroupItemDis
} from './ProfilePersonalInformationForm.elements'

// Views
import { Form, FormTextField } from 'views/components'

const ProfilePersonalInformationForm = (props) => {
  // Destructure
  const { formRef, userProfileData } = props
  const { FullName, User_Role, User_TimeZone, User_ID, User_Name, Business_Name } = userProfileData

  // Hooks
  const { updateProfile } = useUpdateProfile()

  // Variables
  const initialValues = {
    fullName: FullName,
    role: User_Role,
    timezone: User_TimeZone,
  }

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
  })

  // Functions
  const handleOnSubmit = (values) => {
    const { fullName, role, timezone } = values

    const payload = {
      business_name: Business_Name,
      fullname: fullName,
      user_email: User_Name,
      user_id: User_ID,
      user_role: role,
      user_timezone: timezone,
    }

    updateProfile(payload)
  }

  return (
    <ProfilePersonalInformationFormWrapper>
      <Form
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="Full Name" type="text" name="fullName" />
          </FormInputGroupItem>
          <FormInputGroupItemDis>
            <FormTextField label="Role" type="text" name="role" disabled />
          </FormInputGroupItemDis>
          <FormInputGroupItemDis>
            <FormTextField label="Timezone" type="text" name="timezone" disabled />
          </FormInputGroupItemDis>
        </FormInputGroupWrapper>
      </Form>
    </ProfilePersonalInformationFormWrapper>
  )
}

// Default Props
ProfilePersonalInformationForm.defaultProps = {
  formRef: createRef(),
  userProfileData: {},
}

// Proptypes Validation
ProfilePersonalInformationForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  userProfileData: PropTypes.shape({
    FullName: PropTypes.string,
    User_Role: PropTypes.string,
    User_TimeZone: PropTypes.string,
    User_ID: PropTypes.string,
    User_Name: PropTypes.string,
    Business_Name: PropTypes.string,
  }),
}

export default ProfilePersonalInformationForm
