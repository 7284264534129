import { useMutation } from 'react-query'

// Services
import { GetMonitoringTransaction } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: getMonitoringTransaction,
    data: monitoringSearchTransactionData,
    error: monitoringSearchTransactionError,
    isLoading: isMonitoringSearchTransactionLoading,
    isSuccess: isMonitoringSearchTransactionSuccess,
    isError: isMonitoringSearchTransactionError,
  } = useMutation(GetMonitoringTransaction)

  return {
    getMonitoringTransaction,
    monitoringSearchTransactionData,
    monitoringSearchTransactionError,
    isMonitoringSearchTransactionLoading,
    isMonitoringSearchTransactionSuccess,
    isMonitoringSearchTransactionError,
  }
}
