import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// Styled Elements
import {
  DataBreakdownTableWrapper,
  DataBreakdownTableHeader,
  DataBreakdownTableHeaderRow,
  DataBreakdownTableHeaderItem,
  DataBreakdownTableBody,
  DataBreakdownTableRow,
  DataBreakdownTableRowData,
} from './DataBreakdownTable.elements'

const DataBreakdownTable = (props) => {
  // Destructure
  const { headers, data } = props

  // Variable
  const withHeader = headers.length
  const withData = data.length

  return (
    <DataBreakdownTableWrapper>
      <DataBreakdownTableHeader withHeader={withHeader}>
        <DataBreakdownTableHeaderRow>
          {headers &&
            headers.map((name) => <DataBreakdownTableHeaderItem key={uuid()}>{name}</DataBreakdownTableHeaderItem>)}
        </DataBreakdownTableHeaderRow>
      </DataBreakdownTableHeader>
      <DataBreakdownTableBody withData={withData}>
        {data &&
          data.map(({ name, data }) => (
            <DataBreakdownTableRow key={uuid()}>
              <DataBreakdownTableRowData>{name}</DataBreakdownTableRowData>
              {data.map((value) => (
                <DataBreakdownTableRowData key={uuid()}>{value}</DataBreakdownTableRowData>
              ))}
            </DataBreakdownTableRow>
          ))}
      </DataBreakdownTableBody>
    </DataBreakdownTableWrapper>
  )
}

// Default Props
DataBreakdownTable.defaultProps = {
  headers: [],
  data: [],
}

// Proptypes Validation
DataBreakdownTable.propTypes = {
  headers: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
}

export default DataBreakdownTable
