import styled from 'styled-components'

// Assets
import Background from 'assets/images/login-background.png'

export const ResetPasswordWrapper = styled.div`
  min-height: 100vh;

  background-image: url(${Background});
  background-position: center;
  background-size: cover;
  background-position-y: 0px;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
`

export const ResetPasswordInnerWrapper = styled.div``

export const ResetPasswordContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px 0px;
`

export const ResetPasswordFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const ResetPasswordFormInnerWrapper = styled.div``

export const ResetPasswordFormContent = styled.div`
  padding: 54px 41px;
  width: 420px;
  //TODO: Style Variable
  background: #fff;
  border-radius: 5px;
`

export const ResetPasswordHeaderWrapper = styled.div`
  justify-content: center;
  padding: 20px 0px 10px 0px;
`

export const ResetPasswordLogoLink = styled.a``

export const ResetPasswordBrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

export const ResetPasswordBottomWrapper = styled.div`
  align-self: center;

  //TODO: Style Variable
  color: #fff;
`

export const ResetPasswordFormTitle = styled.h1`
  margin-bottom: 0;

  font-size: 23px;
  font-weight: 800;
  color: #393e46;
  text-align: center;
`

export const ResetPasswordFormDescription = styled.p`
  margin: 3px 0px 20px 0px;

  font-size: 16px;
  color: #393e46;
  text-align: center;
`
