import styled from 'styled-components'
// import { Modal } from 'reactstrap'

export const Modal  = styled.div`
  max-width: 533px;
  box-shadow: -2px 4px 12px rgb(0 0 0 / 10%);

  & .modal-body{
    padding:50px 36px;
  }
`
export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const ModalHeader = styled.div`
padding: 20px;
text-align: center;
border-bottom: 1px solid #cbcbcb;
font-size: 16px;
color: #5f6267;
`
export const ModalBodyWrapper = styled.div`
  margin: 0px 0px 0px 0px;
  height: 386px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`

export const UploadOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const UploadOptionItem = styled.div`
  flex: 1;
  overflow: hidden;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  cursor: pointer;
  height: 73px;
  flex: 0 0 31% !important;
  & p{
    font-size:10px;
    color:#5f6267;
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    max-width: 90%;
    text-align: center;
  }
  & img{
    opacity: .6;
  }
  // TODO: Use theme function
 
  &:nth-child(2){
    margin: 10px 10px 10px 0px;
  }
  &:nth-child(1) {
    margin: 10px 10px 10px 0px;
  }
`

export const BackOptionItem = styled.div`
display: flex;
justify-content: space-between;
padding: 0 20px 20px;
width: 100%;
& button{
  padding: 9px 0;
  width: 110px;
  font-weight: 400;
  font-size: 16px;
  color: #393e46;
  transition: all .3s ease-in-out;
  border: none;
  border-radius: 5px;
  background: #f8f9fa;
}
& button:hover{
  background:#d0d0d0
}
`
export const ModalHeaderWrapper = styled(ModalHeader)`
  border: none;
`

export const ModalHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

export const ModalHeaderCloseIcon = styled.div`
  cursor: pointer;
`
