import styled from 'styled-components'

export const CaseManagementTableChartWrapper = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  width: 100%;

  font-size: 15px;
  font-weight: 100;
  color: #393e46;
`
