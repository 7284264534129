// TODO: Refactor

import React, { useState, useEffect, useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Utils
import riskScoreColor from 'common/utils/riskScoreColor'
import { numberWithCommas, abbreviateNumber } from 'common/utils/valueFormat'
import getObjectPropertyByString from 'common/utils/getObjectPropertyByString'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseInvestigation } from 'core/hooks/api'

// Services
import { GetCaseInvestigation } from 'services/api/Cases'

// Views
import { NodeChart } from 'views/components'

// Map Redux Props
const mapStateToProps = ({ investigation }) => ({ investigation })
const mapDispatchToProps = actions

const InvestigationChart = (props) => {
  // Destructure
  const { investigation, actions } = props

  // Refs
  const nodeChartRef = createRef()
  const nodeChartInstance = useRef()

  // Store State
  const { initialNodeData } = investigation

  // Store Actions
  const { setShowHeaderLoader, showAlert } = actions

  // State
  const [chartData, setChartData] = useState({ incoming: [], outgoing: [], loaded: false })

  // Variables
  const chartOptions = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      enterable: true,
    },
    legend: {
      top: '110px',
      left: '20px',
      orient: 'vertical',
      itemGap: 5,
      itemWidth: 0,
      padding: 0,
      data: [
        {
          name: 'incoming',
          icon: 'none',
          textStyle: {
            color: '#555',
            fontWeight: 800,
            fontSize: 16,
            margin: 0,
            padding: 0,
          },
        },
        {
          name: 'outgoing',
          icon: 'none',
          textStyle: {
            color: '#555',
            fontWeight: 800,
            fontSize: 16,
            margin: 0,
            padding: 0,
          },
        },
      ],
      formatter(name) {
        return name.charAt(0).toUpperCase() + name.slice(1)
      },
      selected: {
        incoming: true,
        outgoing: false,
      },
    },
    series: [
      {
        type: 'tree',
        name: 'incoming',
        roam: true,
        initialTreeDepth: -1,
        orient: 'RL',
        data: chartData.incoming,
        zoom: 0.9,
        zoomToNodeRatio: 0.32 * 0.32,
        leaves: {
          label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left',
          },
        },
        right: '50%',
        expandAndCollapse: true,
        animationDuration: 550,
        animationDurationUpdate: 750,
        selectedMode: true,
      },
      {
        type: 'tree',
        name: 'outgoing',
        roam: true,
        initialTreeDepth: -1,
        orient: 'LR',
        data: chartData.outgoing,
        zoom: 0.9,
        zoomToNodeRatio: 0.32 * 0.32,
        leaves: {
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right',
          },
        },
        left: '50%',
        expandAndCollapse: true,
        animationDuration: 550,
        animationDurationUpdate: 750,
      },
    ],
  }

  // Hooks
  const { caseInvestigationData, isCaseInvestigationLoading, getCaseInvestigation } = useFetchCaseInvestigation()

  // Functions
  const handleOnChartClick = (
    { value: { chain, direction, hash, riskScore, type, children }, data, seriesType, event },
    echart
  ) => {
    if (data.children.length) return

    // Show loading feedback when fetching children data
    echart.showLoading('default', { color: '#777' })

    if (event.type === 'click' && seriesType === 'tree') {
      // Fetch children data
      GetCaseInvestigation({
        cipher: hash,
        type,
        chain,
        direction,
        limit: 2,
      })
        .then((response) => {
          // Get current chart data to be updated
          const currentSeriesData = echart.getOption().series.filter(({ name }) => name === direction)[0].data
          const formattedChildrenData = formatChartData(unifyDataFormat(response))
          const chartDataWithAppendedChildren = appendChildrenData(
            { value: { hash, direction, riskScore }, children },
            currentSeriesData,
            formattedChildrenData
          )
          const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)
          const updatedSeriesData = echart.getOption().series.map((seriesItem) => {
            if (seriesItem.name === direction) {
              return { ...seriesItem, data: updatedChartData }
            }
            return seriesItem
          })
          echart.setOption({ ...echart.getOption(), series: updatedSeriesData })
          echart.hideLoading()
        })
        .catch((error) => {
          const responseStatus = error.response.status

          if (responseStatus === 500) {
            // TODO : Refactor
            GetCaseInvestigation({
              cipher: hash,
              type,
              chain,
              direction,
              limit: 2,
            }).then((response) => {
              // Get current chart data to be updated
              const currentSeriesData = echart.getOption().series.filter(({ name }) => name === direction)[0].data
              const formattedChildrenData = formatChartData(unifyDataFormat(response))
              const chartDataWithAppendedChildren = appendChildrenData(
                { value: { hash, direction, riskScore }, children },
                currentSeriesData,
                formattedChildrenData
              )
              const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)
              const updatedSeriesData = echart.getOption().series.map((seriesItem) => {
                if (seriesItem.name === direction) {
                  return { ...seriesItem, data: updatedChartData }
                }
                return seriesItem
              })
              echart.setOption({ ...echart.getOption(), series: updatedSeriesData })
              echart.hideLoading()
            })
            showAlert({
              type: 'error',
              message: 'Error occured while fetching. Please try again.',
            })
            echart.hideLoading()
          } else if (responseStatus === 400) {
            showAlert({
              type: 'info',
              message: 'No further children data',
            })
            echart.hideLoading()
          }
        })
    }
  }

  const handleOnLegendSelectChange = ({ type, name }, echart) => {
    // Validate if event is from tree legend
    if (type === 'legendselectchanged') {
      // Show either incoming/outgoing chart on select
      echart.setOption({
        ...echart.getOption(),
        legend: [
          {
            ...echart.getOption().legend,
            selected: {
              incoming: name !== 'outgoing',
              outgoing: name !== 'incoming',
            },
          },
        ],
      })
    }
  }

  const getLargestNodeValue = (array, innerArrayField, field) => {
    let largestValue = 0

    const getLargestValueRecursively = (array, innerArrayField, field) => {
      if (array && array.length) {
        array.map((item) => {
          const currentValue = getObjectPropertyByString(field, item)

          if (currentValue) {
            if (currentValue > largestValue) largestValue = currentValue
          }

          getLargestValueRecursively(item[innerArrayField], innerArrayField, field)
        })
      }
    }

    getLargestValueRecursively(array, innerArrayField, field)

    return largestValue
  }

  const extractToken = (token) => {
    const tokenListData = []

    const tokenStringItems = token ? token.split(';') : []

    tokenStringItems.map((item) => {
      const [name, value, contract] = item.split(',')

      tokenListData.push({ name, value, contract })
    })

    return tokenListData
  }

  const formaNodeTooltipWindow = ({
    value: { type, asset, direction, value, token, valueUsd, hash, color, riskScore, flag, timeStamp },
  }) => {
    const extractedTokenData = extractToken(token)

    const renderedTokenList = extractedTokenData.map(
      ({ name, value, contract }) =>
        `<span style="cursor: pointer; color: #412ED4;" 
          data-element-type="node-token"
          data-contract=${contract} 
          data-direction=${direction} 
          data-hash=${hash} 
          >
            ${name} 
            <span style="font-size: 10px; font-weight: 400; color: darkgrey;">(${abbreviateNumber(value)})</span>
        </span>`
    )

    return `
      <div style="background: #fff; border: 0; 
        padding: 15px; margin: 0px; border-radius: 5px; 
        border: 1px solid lightgrey; width: 550px;">
        <p style="padding: 0px 0px 10px 5px; margin: 0px; 
        color: grey; font-size: 14px; font-weight: 700; color: black;">${hash}</p>
        <ul style="padding: 0px; margin: 0px; list-style: none; color: black;">
          <li id="this-tooltip" style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid lightgrey; white-space: normal;">
            Type: ${type}
          </li>
          <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid lightgrey; white-space: normal;">
            Asset: ${asset}
          </li>
          <li style=" margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid lightgrey; white-space: normal;">
            ${type === 'transaction' ? `Amount ${asset}` : `Balance ${asset}`}: ${numberWithCommas(value)}
          </li>
          <li style=" margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid lightgrey; white-space: normal;">
            ${type === 'transaction' ? 'Amount USD' : 'Balance USD'}: 
            ${type === 'transaction' ? 'N/A' : numberWithCommas(valueUsd)}
          </li>
          <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid ${color}; white-space: normal;">
            Token: ${token === 'NA' ? 'N/A' : renderedTokenList}
          </li>
          <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid ${color}; white-space: normal;">
            Risk Score: ${riskScore}
          </li>
          <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
          border-left: 5px solid ${color}; white-space: normal;">
            Flags: ${flag}
          </li>
          <li style="margin: -1px; padding: 5px; border-left: 5px solid lightgrey; white-space: normal;">
          ${type === 'transaction' ? 'Timestamp' : 'Last Activity'}: ${timeStamp}
          </li>
        </ul>
      </div>
    `
  }

  const formatChartData = (rawChildrenData) =>
    rawChildrenData.map(
      ({
        asset,
        chain,
        children,
        direction,
        entity,
        color,
        flag,
        hash,
        riskScore,
        token,
        type,
        value,
        valueUsd,
        timeStamp,
      }) => ({
        name: hash,
        value: {
          type,
          asset,
          chain,
          amount: `${value} ${asset}`,
          value,
          valueUsd,
          token,
          entity,
          hash,
          riskScore,
          flag,
          color,
          direction,
          timeStamp,
          children,
        },
        symbol: type === 'transaction' ? 'square' : 'circle',
        symbolSize: 0,
        itemStyle: {
          borderWidth: 1,
          borderColor: '#777',
          color: riskScoreColor(riskScore),
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          borderColor: 'none',
          padding: 0,
          formatter: formaNodeTooltipWindow,
        },
        label: {
          position: direction === 'outgoing' ? 'left' : 'right',
          verticalAlign: direction === 'middle',
          align: direction === 'outgoing' ? 'right' : 'left',
          formatter: ({ value: { hash, amount } }) => {
            const shortenedHash = `${hash.slice(0, 5)}[...]${hash.slice(hash.length - 5)}`

            return `{amount|${numberWithCommas(amount)}}\n{hash|${shortenedHash}}`
          },
          distance: 0,
          rich: {
            amount: {
              padding: 0,
              color: '#777',
              fontSize: 10,
              fontWeight: 600,
            },
            hash: {
              padding: 0,
              color: '#777',
              fontSize: 10,
              fontWeight: 600,
            },
          },
        },
        children: [],
      })
    )

  const unifyDataFormat = (data) =>
    data.map(({ asset, chain, color, entity, flag, riskScore, token, type, ...rest }) => ({
      hash: type === 'transaction' ? rest.transaction : rest.address,
      value: type === 'transaction' ? rest.amount : rest.balance,
      valueUsd: type === 'transaction' ? rest.amountUSD : rest.balanceUSD,
      timeStamp: type === 'transaction' ? rest.timestamp : rest.lastActivity,
      asset,
      chain,
      color,
      entity,
      flag,
      riskScore,
      token,
      type,
    }))

  const getParentData = (chartData, hash) => {
    let parentData = null

    const searchParentDataRecursively = (chartData, hash) =>
      chartData.map((item) => {
        if (item.value.hash === hash) {
          parentData = item
        }
        searchParentDataRecursively(item.children, hash)
      })

    searchParentDataRecursively(chartData, hash)

    return parentData
  }

  const appendChildrenData = (parent, chartData, children) => {
    const childrenData = children.map((data) => ({
      ...data,
      value: {
        ...data.value,
        direction: parent.value.direction,
        color: riskScoreColor(parent.value.riskScore),
        isToken: parent.value.isToken || false,
        children: [],
      },
    }))

    const updateRawChartDataRecursively = (parent, chartData) =>
      chartData.map((item) => {
        if (item.value.hash === parent.value.hash && !item.children.length) {
          return { ...item, children: childrenData }
        }
        if (item.value.hash === parent.value.hash && item.children.length) {
          return { ...item, children: [...parent.children, ...childrenData] }
        }
        if (item.value.hash !== parent.value.hash && item.children.length) {
          return { ...item, children: updateRawChartDataRecursively(parent, item.children) }
        }
        return item
      })

    const updatedRawChartData = updateRawChartDataRecursively(parent, chartData)

    return updatedRawChartData
  }

  const updateNodeStyles = (chartData) => {
    const largestChartValue = getLargestNodeValue(chartData, 'children', 'value.value')

    const updateChartSymbolSizeRecursively = (chartData) =>
      chartData.map((item) => {
        const safeValue = item.value.value / largestChartValue || 0

        const cappedAmountForGraph = safeValue * 30 + 5

        return {
          ...item,
          symbolSize: cappedAmountForGraph,
          label: {
            ...item.label,
            distance: -(cappedAmountForGraph / 2),

            position: item.value.direction === 'outgoing' ? 'left' : 'right',
            verticalAlign: item.value.direction === 'middle',
            align: item.value.direction === 'outgoing' ? 'right' : 'left',

            rich: {
              ...item.label.rich,
              amount: {
                ...item.label.rich.amount,
                padding:
                  item.value.direction === 'outgoing'
                    ? [0, cappedAmountForGraph / 2 + 5, -5, 0]
                    : [0, 0, -5, cappedAmountForGraph / 2 + 5],
              },
              hash: {
                ...item.label.rich.amount,
                padding:
                  item.value.direction === 'outgoing'
                    ? [0, cappedAmountForGraph / 2 + 5, 0, 0]
                    : [0, 0, 0, cappedAmountForGraph / 2 + 5],
              },
            },
          },
          children: item.children.length ? updateChartSymbolSizeRecursively(item.children) : [],
        }
      })

    const data = updateChartSymbolSizeRecursively(chartData)

    return data
  }

  // useEffect
  useEffect(() => {
    getCaseInvestigation({
      cipher: initialNodeData.hash,
      type: initialNodeData.type,
      chain: initialNodeData.chain,
      direction: 'incoming',
      limit: 2,
    })

    setChartData((prevState) => ({
      ...prevState,
      incoming: updateNodeStyles(formatChartData([initialNodeData])),
      outgoing: updateNodeStyles(formatChartData([{ ...initialNodeData, direction: 'outgoing' }])),
    }))

    window.addEventListener('click', (event) => {
      const { elementType, contract, hash, direction } = event.target.dataset

      if (elementType) {
        const echart = nodeChartInstance.current.getEchartsInstance()

        const currentSeriesDirectionData = echart.getOption().series.filter(({ name }) => name === direction)[0].data

        const parentData = getParentData(currentSeriesDirectionData, hash)

        if (!parentData.children.length) {
          showAlert({
            type: 'warning',
            message: 'Node should have children first before selecting tokens',
          })
        } else {
          echart.showLoading('default', { color: '#777' })

          const {
            value: { type, chain, riskScore },
            children,
          } = parentData

          GetCaseInvestigation({
            cipher: hash,
            type,
            chain,
            direction,
            contract,
            limit: 2,
          })
            .then((response) => {
              // Get current chart data to be updated
              const currentSeriesData = echart.getOption().series.filter(({ name }) => name === direction)[0].data

              const formattedChildrenData = formatChartData(unifyDataFormat(response))

              const chartDataWithAppendedChildren = appendChildrenData(
                { value: { hash, direction, riskScore, isToken: true }, children },
                currentSeriesData,
                formattedChildrenData
              )

              const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)

              const updatedSeriesData = echart.getOption().series.map((seriesItem) => {
                if (seriesItem.name === direction) {
                  return { ...seriesItem, data: updatedChartData }
                }
                return seriesItem
              })

              echart.setOption({ ...echart.getOption(), series: updatedSeriesData })
              echart.hideLoading()
            })
            .catch(({ response }) => {
              const responseStatus = response.status
              if (responseStatus === 400) {
                showAlert({
                  type: 'error',
                  message: 'Error occured while fetching token data.',
                })
                echart.hideLoading()
              } else {
                GetCaseInvestigation({
                  cipher: hash,
                  type,
                  chain,
                  direction,
                  contract,
                  limit: 2,
                }).then((response) => {
                  // TODO : Refactor
                  const currentSeriesData = echart.getOption().series.filter(({ name }) => name === direction)[0].data

                  const formattedChildrenData = formatChartData(unifyDataFormat(response))

                  const chartDataWithAppendedChildren = appendChildrenData(
                    { value: { hash, direction, riskScore, isToken: true }, children },
                    currentSeriesData,
                    formattedChildrenData
                  )

                  const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)

                  const updatedSeriesData = echart.getOption().series.map((seriesItem) => {
                    if (seriesItem.name === direction) {
                      return { ...seriesItem, data: updatedChartData }
                    }
                    return seriesItem
                  })

                  echart.setOption({ ...echart.getOption(), series: updatedSeriesData })
                  echart.hideLoading()
                })
              }
            })
        }
      }
    })
  }, [])

  useEffect(() => {
    if (isCaseInvestigationLoading) {
      setShowHeaderLoader(true)
    } else {
      setShowHeaderLoader(false)
    }
  }, [isCaseInvestigationLoading])

  useEffect(() => {
    if (nodeChartRef.current) {
      nodeChartInstance.current = nodeChartRef.current
    }
  }, [nodeChartRef])

  useEffect(() => {
    if (caseInvestigationData) {
      if (!chartData.loaded) {
        const formattedChildrenData = formatChartData(unifyDataFormat(caseInvestigationData))

        const chartDataWithAppendedChildren = appendChildrenData(
          chartData.incoming[0],
          chartData.incoming,
          formattedChildrenData
        )

        const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)

        setChartData((prevState) => ({
          ...prevState,
          incoming: updatedChartData,
          loaded: true,
        }))
      }
    }
  }, [caseInvestigationData])

  return (
    chartData.loaded && (
      <NodeChart
        chartRef={nodeChartRef}
        style={{
          height: '100vh',
          width: '100vw',
        }}
        options={chartOptions}
        onEvents={{
          click: handleOnChartClick,
          legendselectchanged: handleOnLegendSelectChange,
        }}
      />
    )
  )
}

// Default Props
InvestigationChart.defaultProps = {
  investigation: {},
  actions: {},
}

// Proptypes Validation
InvestigationChart.propTypes = {
  investigation: PropTypes.shape({
    initialNodeData: PropTypes.instanceOf(Object),
  }),
  actions: PropTypes.shape({
    setInvestigationAlert: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestigationChart)
