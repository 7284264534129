import styled from 'styled-components'

// Library Components
import { Nav } from 'reactstrap'

export const TabsOuterWrapper = styled.div`
  display: flex;
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 5px;

  // Themed Properties
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};

  // Responsive Properties
  ${({ theme, type }) =>
    theme.functions.mediaQuery('width', ['100%', '100%', '100%', '100%', '100%', type !== 'detached' ? '100%' : '74%'])}
`

export const TabsHeader = styled.div`
  position: relative;
  padding: 15px 15px 15px 20px;

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const TabHeaderIcon = styled.div`
  display: table-cell;
  vertical-align: middle;
`

export const TabsHeaderTitle = styled.div`
  display: table-cell;
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
  padding-left: 10px;
  vertical-align: middle;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TabsBody = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: space-between;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('flex-direction', ['column', 'column', 'column', 'column', 'column', 'row'])}
`

export const TabsTopNav = styled.div`
  width: 100%;
  padding: 10px 15px;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['unset', 'unset', 'unset', 'unset', 'unset', 'none'])}
`

export const TabsSideNav = styled.div`
  width: 25%;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('border-right', [
      'none',
      'none',
      'none',
      'none',
      'none',
      `1px solid ${theme.colors.lightGrey}`,
    ])}
  ${({ theme, type }) =>
    theme.functions.mediaQuery('display', [
      'none',
      'none',
      'none',
      'none',
      'none',
      type === 'detached' ? 'none' : 'unset',
    ])}
`

export const DetachedSideNav = styled(Nav)`
  padding: 74px 0px;
  margin: 0px 20px 0px 0px;
  width: 24%;
  background: #fff;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};

  // Responsive Properties
  ${({ theme, type }) =>
    theme.functions.mediaQuery('display', [
      'none',
      'none',
      'none',
      'none',
      'none',
      type !== 'detached' ? 'none' : 'unset',
    ])}
`

export const TabsContentWrapper = styled.div`
  width: 100%;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('padding', ['30px', '30px', '30px', '30px', '30px', '30px'])}

  // Props Condition
  ${({ type }) => type === 'detached' && 'border-left: none;'}
`

export const TabsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 0px 30px;

  width: 100%;
  height: 68px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const TabsSideNavWrapper = styled.div`
  padding: 30px 0px;
`

export const TabsSideNavItem = styled.button`
  width: 100%;
  min-height: 48px;

  font-weight: 400;
  font-size: 16px;
  color: #393e46;

  background-color: #fff;
  border: none;

  // Props Condition
  ${({ active }) => active && 'font-weight: 700;  background-color: #e3e3e3;'}
`

export const TabsTopNavWrapper = styled.ul`
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;

  -ms-overflow-style: none;
  scrollbar-width: none;

  // TODO : Use theme functions
  &::-webkit-scrollbar {
    display: none;
  }
`

export const TabsTopNavItem = styled.li`
  display: inline-block;
  margin: 10px 15px 0px 0px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  // Themed Properties
  color: ${({ theme }) => theme.colors.lightGrey};

  // Props Condition
  ${({ active, theme }) =>
    active &&
    `
      padding-bottom: 3px;
      border-bottom: 2px solid ${theme.colors.lightGrey};
      color: ${theme.colors.gray};
    `}
`
