export const CASE_TYPE_OPTIONS = [
  { label: 'All' },
  { value: 1, label: 'Natural Person' },
  { value: 2, label: 'Legal Person' },
]

export const CASE_RISK_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' },
]

export const ADVERSE_MEDIA_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]

export const CASE_STATUS_OPTIONS = [
  { label: 'All' },
  { value: 0, label: 'Inactive' },
  { value: 1, label: 'Active' },
  { value: 2, label: 'On Hold' },
  { value: 3, label: 'Dormant' },
]

export const POLITICALLY_EXPOSED_PERSON_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]
