import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  countryListOptions: [],
  activityToRemove: {
    type: '',
    hash: '',
  },
  addressOrTransactionIdToAssign: {
    type: '',
    id: '',
  },
}

const store = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setCountryListOptions: (state, { payload }) => {
      state.countryListOptions = payload
    },
    setActivityToRemove: (state, { payload }) => {
      state.activityToRemove = payload
    },
    setAddressOrTransactionIdToAssign: (state, { payload }) => {
      state.addressOrTransactionIdToAssign = payload
    },
  },
})

export default store
