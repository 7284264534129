import styled from 'styled-components'

import { Button } from '@material-ui/core'

export const ButtonWrapper = styled(Button)`
  cursor: pointer;
  width: fit-content;
  font-weight: 100;
  font-size: 16px;
  padding: 5px 11px;

  /*-------------------------------
    Events Properties
  -------------------------------*/
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.mediumPurple};
    border: 1px solid ${({ theme }) => theme.colors.mediumPurple};
    outline: none;
    padding: 5px 11px;
    opacity: 0.9;

    ${({ theme, color }) => color === 'default' && `background: ${theme.colors.lightGrey};`}
    ${({ theme, color }) => color === 'default' && `border: 1px solid ${theme.colors.lightGrey};`}
    ${({ theme, color }) => color === 'default' && `color: ${theme.colors.darkGray};`}

    ${({ theme, color }) => color === 'primary' && `background: ${theme.colors.mediumPurple};`}
    ${({ theme, color }) => color === 'primary' && `border: 1px solid ${theme.colors.mediumPurple};`}

    ${({ theme, color }) => color === 'secondary' && `background: ${theme.colors.red};`}
    ${({ theme, color }) => color === 'secondary' && `border: 1px solid ${theme.colors.red};`}
  }

  /*-------------------------------
    Themed Properties
  -------------------------------*/
  border: 1px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue};

  /*-------------------------------
    Variant
  -------------------------------*/
  ${({ theme, variant }) => variant === 'outlined' && `border: 1px solid ${theme.colors.mediumPurple};`}
  ${({ theme, variant }) => variant === 'outlined' && `background: ${theme.colors.white};`}
  ${({ theme, variant }) => variant === 'outlined' && `color: ${theme.colors.mediumPurple};`}
  ${({ theme, variant }) =>
    variant === 'outlined' &&
    `// TODO: Fix background color issue on focus and use theme mixins
      &:hover,
      &:focus {
        color: ${theme.colors.mediumPurple};
        border: 1px solid ${theme.colors.mediumPurple};
        outline: none;

        // TODO: Use Theme
        background: #e8e6fa;
      }`}

  ${({ theme, color }) => color === 'default' && `background: ${theme.colors.white};`}
  ${({ theme, color }) => color === 'default' && `border: 1px solid ${theme.colors.white};`}
  ${({ theme, color }) => color === 'default' && `color: ${theme.colors.darkGray};`}

  ${({ theme, color }) => color === 'secondary' && `background: ${theme.colors.red};`}
  ${({ theme, color }) => color === 'secondary' && `border: 1px solid ${theme.colors.red};`}


  /*-------------------------------
    Resposive Properties
  -------------------------------*/
  ${({ theme }) =>
    theme.functions.mediaQuery('font-size', ['14px', '14px', '14px', '14px', '14px', '14px', '17px', '17px'])}

  /*-------------------------------
    Conditional Properties
  -------------------------------*/
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  
`
