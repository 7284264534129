import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  AccordionTableDetailsWrapper,
  AccordionTableDetailsItem,
  AccordionTableDetailsTitle,
  TransactionDetailsWrapper,
  AccountDetailsWrapper,
} from './AccordionTableDetails.elements'

// Views
import { DataBreakdownTable } from 'views/components'

const AccordionTableDetails = (props) => {
  // Destructure
  const {
    data: { itemDetails, totalDetails, accountDetails },
    type,
  } = props

  // Variables
  const itemTitle = type === 'address' ? 'Address Details' : 'Transaction Details'

  return (
    <AccordionTableDetailsWrapper>
      <TransactionDetailsWrapper>
        <AccordionTableDetailsTitle>{itemTitle}</AccordionTableDetailsTitle>
        <AccordionTableDetailsItem>
          <DataBreakdownTable headers={itemDetails.headers} data={itemDetails.data} />
        </AccordionTableDetailsItem>
        <AccordionTableDetailsItem>
          <DataBreakdownTable headers={totalDetails.headers} data={totalDetails.data} />
        </AccordionTableDetailsItem>
      </TransactionDetailsWrapper>
      <AccountDetailsWrapper>
        {accountDetails && (
          <>
            <AccordionTableDetailsTitle>Account Information</AccordionTableDetailsTitle>
            <AccordionTableDetailsItem>
              <DataBreakdownTable headers={accountDetails.headers} data={accountDetails.data} />
            </AccordionTableDetailsItem>
          </>
        )}
      </AccountDetailsWrapper>
    </AccordionTableDetailsWrapper>
  )
}

// Default Props
AccordionTableDetails.defaultProps = {
  type: '',
  data: {},
}

// Proptypes Validation
AccordionTableDetails.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    itemDetails: PropTypes.shape({ headers: PropTypes.instanceOf(Array), data: PropTypes.instanceOf(Array) }),
    totalDetails: PropTypes.shape({ headers: PropTypes.instanceOf(Array), data: PropTypes.instanceOf(Array) }),
    accountDetails: PropTypes.shape({ headers: PropTypes.instanceOf(Array), data: PropTypes.instanceOf(Array) }),
  }),
}

export default AccordionTableDetails
