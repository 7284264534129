import styled from 'styled-components'

// Library Components
import { Autocomplete } from '@material-ui/lab'

export const AutoCompleteSearchInputWrapper = styled.div`
  position: relative;
`

export const AutoCompleteSearchInputInput = styled(Autocomplete)`
  padding: 0px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
`

export const SearchButton = styled.button`
  position: absolute;
  right: 2px;
  top: 1px;
  z-index: 5;

  width: 50px;
  height: 46px;

  transition: all 0.3s ease-in-out;

  background-color: #fff;

  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  padding: 0.4rem 0.75rem;

  // TODO: Use theme functions
  &:hover {
    background-color: #f8f9fa;
  }

  // Themed Properties
  border-left: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const SearchIconWrapper = styled.div`
  opacity: 0.5;
  border-style: none;
`
