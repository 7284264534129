import styled from 'styled-components'

export const ResetPasswordFormWrapper = styled.div``

export const ResetPasswordFormLabel = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;

  // TODO: Use theme colors
  color: #393e46;
`

export const FormInputGroupWrapper = styled.div`
  margin: 0px 0px 30px 0px;
`

export const FormInputGroupItem = styled.div`
  margin: 0px 0px 20px 0px;
`
