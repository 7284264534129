import React, { createRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Constants
import { CASE_STATUS_OPTIONS, CASE_TYPE_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAutoCompleteCustomer } from 'core/hooks/api'

// Styled Elements
import {
  CaseManagementFilterFormWrapper,
  CaseManagementFilterInner,
  CaseManagementFilterItem,
  FormAutoCompleteWrapper,
} from './CaseManagementFilterForm.elements'

// Views
import { Form, FormSelect, FormAutoComplete } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

const CaseManagementFilterForm = (props) => {
  // Destructure Props
  const { formRef, filters, actions } = props

  const { caseManagementFilters } = filters

  // Store Actions
  const { setCaseManagementFilters } = actions

  // Variables
  const initialValues = {
    customerId: caseManagementFilters.customerId || '',
    caseType: caseManagementFilters.caseType || undefined,
    caseStatus: caseManagementFilters.caseStatus || undefined,
  }

  const validationSchema = Yup.object().shape({})

  // States
  const [customerSearchOptions, setCustomerSearchOptions] = useState([])

  // Hooks
  const { autoCompleteCustomerData, getAutoCompleteCustomer } = useFetchAutoCompleteCustomer()

  // Functions
  const handleOnSubmit = (values) => {
    const { customerId, caseType, caseStatus } = values

    setCaseManagementFilters({
      customerId,
      caseType: Number.isNaN(+caseType) ? undefined : +caseType,
      caseStatus: Number.isNaN(+caseStatus) ? undefined : +caseStatus,
    })
  }

  const handleOnAutoCompleteInputChange = debounce((e) => {
    if (e) {
      const queryValue = e.target.value

      if (queryValue) getAutoCompleteCustomer({ customer_id: queryValue })
    }
  }, 500)

  // useEffect
  useEffect(() => {
    if (autoCompleteCustomerData) {
      setCustomerSearchOptions(() =>
        autoCompleteCustomerData.map(({ Customer_ID }) => ({ label: Customer_ID, value: Customer_ID }))
      )
    }
  }, [autoCompleteCustomerData])

  return (
    <CaseManagementFilterFormWrapper>
      <Form
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        onChange={(e) => {}}
      >
        <FormAutoCompleteWrapper>
          <FormAutoComplete
            disableClearable
            name="customerId"
            placeholder="Search Customer ID"
            options={customerSearchOptions}
            getOptionSelected={(option) => option.value}
            getOptionLabel={(option) => option.label || ''}
            onInputChange={handleOnAutoCompleteInputChange}
          />
        </FormAutoCompleteWrapper>
        <CaseManagementFilterInner>
          <CaseManagementFilterItem>
            <FormSelect placeholder="Case Type" name="caseType" options={CASE_TYPE_OPTIONS} />
          </CaseManagementFilterItem>
          <CaseManagementFilterItem>
            <FormSelect placeholder="Status" name="caseStatus" options={CASE_STATUS_OPTIONS} />
          </CaseManagementFilterItem>
        </CaseManagementFilterInner>
      </Form>
    </CaseManagementFilterFormWrapper>
  )
}

// Default Props
CaseManagementFilterForm.defaultProps = {
  formRef: createRef(),
  filters: {},
  actions: {},
}

// Proptypes Validation
CaseManagementFilterForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  filters: PropTypes.shape({ caseManagementFilters: PropTypes.instanceOf(Object) }),
  actions: PropTypes.shape({
    setCaseManagementFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagementFilterForm)
