import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Assets
import SearchIcon from 'assets/images/search.svg'

// Styled Elements
import { SearchbarWrapper, SearchInputField, SearchButton, SearchIconWrapper } from './SearchInput.elements'

// Views
import { Image } from 'views/components'

const SearchInput = (props) => {
  // Destructure
  const { placeholder, error, onChange } = props

  // States
  const [value, setValue] = useState('')

  // Functions
  const handleSubmit = () => {
    onChange(value)
  }

  const handleInputChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <SearchbarWrapper>
      <SearchInputField className={props.className ? "error" : ""} placeholder={placeholder} error={error ? 1 : 0} value={value} onChange={handleInputChange} />
      <SearchButton type="submit" onClick={() => props.handleSubmit(value)}>
        <SearchIconWrapper>
          <Image src={SearchIcon} />
        </SearchIconWrapper>
      </SearchButton>
    </SearchbarWrapper>
  )
}

// Default Props
SearchInput.defaultProps = {
  placeholder: 'Search',
  error: false,
  onChange: () => { },
}

// Proptypes Validation
SearchInput.propTypes = {
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
}

export default SearchInput
