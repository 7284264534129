import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { BadgeTextWrapper, BadgeTextBackground, BadgeTextValue } from './BadgeText.elements'

const BadgeText = (props) => {
  // Destructure
  const { width, height, color, children } = props

  return (
    <BadgeTextWrapper width={width} height={height}>
      <BadgeTextBackground color={color} />
      <BadgeTextValue color={color}>{children}</BadgeTextValue>
    </BadgeTextWrapper>
  )
}

// Default Props
BadgeText.defaultProps = {
  width: '',
  height: '',
  color: '',
  children: '',
}

// Proptypes Validation
BadgeText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default BadgeText
