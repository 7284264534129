import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ModalHeaderWrapper, ModalHeaderTitle, ModalHeaderCloseIcon } from './ModalHeader.elements'

const ModalHeader = (props) => {
  // Destructure
  const { children, close, ...rest } = props

  return (
    <ModalHeaderWrapper close={<ModalHeaderCloseIcon>{close}</ModalHeaderCloseIcon>} {...rest}>
      <ModalHeaderTitle>{children}</ModalHeaderTitle>
    </ModalHeaderWrapper>
  )
}

// Default Props
ModalHeader.defaultProps = {
  children: '',
  close: '',
}

// Proptypes Validation
ModalHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  close: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default ModalHeader
