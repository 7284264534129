import styled from 'styled-components'

// Assets
import Background from 'assets/images/login-background.png'

export const LoginWrapper = styled.div`
  min-height: 100vh;

  background-image: url(${Background});
  background-position: center;
  background-size: cover;
  background-position-y: 0px;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
`

export const LoginInnerWrapper = styled.div`
  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('display', ['block', 'block', 'block', 'block', 'block', 'block', 'flex'])}
  ${({ theme }) => theme.functions.mediaQuery('padding', ['0px', '0px', '0px', '0px', '0px', '0px', '0px', '0px 70px'])}
`

export const LoginContentWrapper = styled.div`
  flex: 1;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', [
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px',
    ])}
`

export const LoginFormWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', ['10px', '10px', '10px', '10px', '10px', '10px', '130px 40px 0px 20px'])}
`

export const LoginFormInnerWrapper = styled.div`
  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('width', ['98%', '98%', '98%', '420px'])}
`

export const LoginFormContent = styled.div`
  padding: 54px 41px 20px 41px;
  width: 100%;

  //TODO: Style Variable
  background: #fff;
  border-radius: 5px;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', [
      '40px 40px 20px 40px',
      '40px 40px 20px 40px',
      '40px 40px 20px 40px',
      '54px 41px 20px 41px',
      '54px 41px 50px 41px',
      '54px 41px 50px 41px',
      '54px 41px 50px 41px',
    ])}
`

export const LoginHeaderWrapper = styled.div`
  padding: 20px;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'block'])}
  ${({ theme }) =>
    theme.functions.mediaQuery('justify-content', [
      'center',
      'center',
      'center',
      'center',
      'center',
      'center',
      'flex-start',
    ])}
`

export const LoginArticleWrapper = styled.div`
  padding: 80px 0px 0px 0px;

  // TODO: Use theme function
  &:hover {
    color: #fff;
  }

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['none', 'none', 'none', 'none', 'none', 'none', 'block'])}
`

export const LoginLogoLink = styled.a`
  height: 70px;
`

export const LoginBrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

export const LoginBottomWrapper = styled.div`
  align-self: center;

  //TODO: Style Variable
  color: #fff;
`

export const LoginBrandTitle = styled.p`
  font-size: 16px;
  font-weight: 100;

  //TODO: Style Variable
  color: #fff;
`

export const LoginBrandText = styled.h2`
  margin: 10px 0;
  max-width: 442px;
  font-size: 40px;

  font-size: 37px;
  line-height: 1.2em;

  //TODO: Style Variable
  color: #fff;
`

export const ReadMoreLink = styled.a`
  font-size: 16px;
  font-weight: 100;

  //TODO: Style Variable
  color: #fff;
`

export const PrivacyPolicyLink = styled.a`
  display: block;
  margin: 20px 0px 0px 0px;
  width: 100%;

  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;

  // TODO: Use theme color
  color: #fff;

  // TODO: Use theme functions
  &:hover {
    color: #fff;
  }
`

export const ReadMoreLinkLogo = styled.img`
  width: 20px;
`

export const LoginFormTitle = styled.h1`
  margin-bottom: 0;

  font-size: 23px;
  font-weight: 800;
  color: #393e46;
  text-align: center;
`

export const LoginFormDescription = styled.p`
  margin: 3px 0px 20px 0px;

  font-size: 16px;
  color: #393e46;
  text-align: center;
`

export const LoginFormFooterText = styled.p`
  margin: 35px 0px 10px 0px;

  font-size: 16px;
  color: #393e46;
  text-align: center;
  font-weight: 100;
`

export const LoginFormFooterLink = styled.a`
  font-weight: 500;
  color: #393e46;
`
