import { useMutation } from 'react-query'

    // Services
import { RemoveWhitelistingAddress } from 'services/api/Whitelisting'
    
    export default () => {
      const {
        mutate: removeWhitelistingAddress,
        data: removewhitelistinglisteAddress,
        error: removewhitelistingReportError,
        isLoading: isWhitelistingReportLoading,
        isSuccess: isWhitelistingReportSuccess,
        isError: isWhitelistingReportError,
      } = useMutation(RemoveWhitelistingAddress)
    
      return {
        removeWhitelistingAddress,
        removewhitelistinglisteAddress,
        removewhitelistingReportError,
        isWhitelistingReportLoading,
        isWhitelistingReportSuccess,
        isWhitelistingReportError,
      }
    }