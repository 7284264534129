import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Library Components
import { TextField } from '@material-ui/core'

// Assets
import SearchIcon from 'assets/images/search.svg'

// Styled Elements
import {
  AutoCompleteSearchInputWrapper,
  AutoCompleteSearchInputInput,
  SearchButton,
  SearchIconWrapper,
} from './AutoCompleteSearchInput.elements'

// Views
import { Image } from 'views/components'

const AutoCompleteSearchInput = (props) => {
  // Destructure
  const {
    type,
    label,
    options,
    placeholder,
    variant,
    onInputChange,
    getOptionSelected,
    getOptionLabel,
    onSelect,
    onBlur,
    onSubmit,
    ...rest
  } = props

  // States
  const [searchEvent, setSearchEvent] = useState('')

  // Functions
  const handleOnSelect = (e) => {
    onSelect(e)
    setSearchEvent(e)
  }

  const handleOnInputChange = (e) => {
    onInputChange(e)
    setSearchEvent(e)
  }

  const handleOnSubmit = () => onSubmit(searchEvent)

  return (
    <AutoCompleteSearchInputWrapper>
      <AutoCompleteSearchInputInput
        disableClearable
        type={type}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        onSelect={handleOnSelect}
        onInputChange={handleOnInputChange}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} variant={variant} />}
        {...rest}
      />
      <SearchButton type="button" onClick={handleOnSubmit}>
        <SearchIconWrapper>
          <Image src={SearchIcon} />
        </SearchIconWrapper>
      </SearchButton>
    </AutoCompleteSearchInputWrapper>
  )
}

// Default Props
AutoCompleteSearchInput.defaultProps = {
  type: '',
  label: '',
  placeholder: '',
  variant: 'outlined',
  options: [],
  onSelect: () => {},
  onBlur: () => {},
  onSubmit: () => {},
  onInputChange: () => {},
  getOptionSelected: () => {},
  getOptionLabel: () => {},
}

// Proptypes Validation
AutoCompleteSearchInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
}

export default AutoCompleteSearchInput
