import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: 100%;
`

export const Label = styled.label``

export const TextFieldInput = styled.input`
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
  outline: none;
  -webkit-font-smoothing: antialiased;

  // TODO: Use theme color
  background-color: white;
  border: 1px solid #ced4da;

  // Conditional Properties
  ${({ disabled }) => disabled && 'background-color: #f8f9fa'}
`
