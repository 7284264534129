export default (value) => {
  switch (value) {
    case 'BTC':
      return 'bitcoin'

    case 'ETH':
      return 'ethereum'

    default:
  }
}
