import { useEffect } from 'react'
import { useMutation } from 'react-query'

// Services
import { Login } from 'services/api/Auth'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

export default () => {
  const {
    mutate: loginUser,
    data: loginUserData,
    error: loginUserError,
    isLoading: isLoginUserLoading,
    isSuccess: isLoginUserSuccess,
    isError: isLoginUserError,
  } = useMutation(Login)

  const [, setUserCredentials] = useLocalStorage('userCredentials', {})
  const [, setIsLoggedIn] = useLocalStorage('isLoggedIn', false)

  // Save User Credentials to LocalStorage
  useEffect(() => {
    if (loginUserData && !isLoginUserError) {
      setUserCredentials(loginUserData)
      setIsLoggedIn(true)
      window.location.reload()
    }
  }, [loginUserData])

  return { loginUser, loginUserData, loginUserError, isLoginUserLoading, isLoginUserSuccess, isLoginUserError }
}
