import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// Library Components
import { Space } from 'antd'

// Assets
import MenuIcon from 'assets/images/layer.svg'
import FilterIcon from 'assets/images/filter.svg'
import ArrowDownIcon from 'assets/images/arrow-down.svg'
import logoIcon from 'assets/images/Layer.png'

// Styled Elements
import {
  TableWrapper,
  TableHeaderWrapper,
  HeaderTitleWrapper,
  HeaderIcon,
  HeaderTitle,
  HeaderFilterButton,
  FilterWrapper,
  TableBodyOverflowWrapper,
  TableBodyWrapper,
  TableBodyHeaderWrapper,
  TableBodyHeaderItem,
  TableBodyHeaderText,
  TableBodySubHeaderText,
  TableBodyHeaderIcon,
  HeaderOptionsWrapper,
} from './Table.elements'

// Views
import { Image, IconButton, Pagination } from 'views/components'

const Table = (props) => {
  // Destructure
  const {
    title,
    headers,
    headerOptions,
    defaultSortOptions,
    children,
    totalItems,
    minWidth,
    filterComponents,
    onChange,
    onFilterToggle,
  } = props

  // States
  const [collapseFilter, setCollapseFilter] = useState(true)
  const [sortDirection, setSortDirection] = useState(defaultSortOptions.sortDirection || 'ASC')
  const [sortBy, setsortBy] = useState(defaultSortOptions.sortBy || '')
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  // Variables
  const withHeader = headers.length
  const isHeaderSortActive = ({ identifier }) => identifier === sortBy

  // Functions
  const handleCollapseFilter = () => {
    if (window.innerWidth < 768) {
      onFilterToggle()
    } else {
      setCollapseFilter((prevState) => !prevState)
    }
  }

  const handleSort = (args) => {
    const { identifier } = args
    const sortDirectionToggle = sortDirection === 'DESC' ? 'ASC' : 'DESC'

    if (sortBy !== identifier) {
      setsortBy(identifier)
      setSortDirection('ASC')
    } else {
      setSortDirection(sortDirectionToggle)
    }
  }

  const handlePageChange = ({ pageIndex, pageSize }) => {
    setPageIndex(pageIndex)
    setPageSize(pageSize)
  }

  // useEffect
  useEffect(() => {
    onChange({ sortBy, sortDirection, pageIndex, pageSize })
  }, [sortDirection, sortBy, pageIndex, pageSize])

  return (
    <>
      <TableWrapper allowZeroExpanded>
        <TableHeaderWrapper>
          <HeaderTitleWrapper>
            <HeaderIcon>
              <Image src={logoIcon} width={30} height={30} />
            </HeaderIcon>
            <HeaderTitle>{title}</HeaderTitle>
          </HeaderTitleWrapper>
          <HeaderFilterButton>
            <Space>
              <IconButton onClick={handleCollapseFilter}>
                <Image src={FilterIcon} width={20} height={20} />
              </IconButton>
              <HeaderOptionsWrapper>
                <Space>{headerOptions}</Space>
              </HeaderOptionsWrapper>
            </Space>
          </HeaderFilterButton>
        </TableHeaderWrapper>
        <FilterWrapper collapseFilter={collapseFilter} withFilter={filterComponents}>
          {filterComponents}
        </FilterWrapper>
        <TableBodyOverflowWrapper>
          <TableBodyWrapper minWidth={minWidth}>
            <TableBodyHeaderWrapper showHeader={withHeader}>
              {headers.map(({ name, subHeader, sort, identifier, width }) => (
                <TableBodyHeaderItem
                  key={uuid()}
                  sortable={sort}
                  width={width}
                  onClick={() => sort && handleSort({ identifier })}
                >
                  <TableBodyHeaderText>
                    {name}
                    {subHeader && <TableBodySubHeaderText>{subHeader}</TableBodySubHeaderText>}
                  </TableBodyHeaderText>
                  {sort && (
                    <TableBodyHeaderIcon sortDirection={sortDirection} active={isHeaderSortActive({ identifier })}>
                      <Image src={ArrowDownIcon} width={18} height={18} />
                    </TableBodyHeaderIcon>
                  )}
                </TableBodyHeaderItem>
              ))}
            </TableBodyHeaderWrapper>
            {children}
          </TableBodyWrapper>
        </TableBodyOverflowWrapper>
      </TableWrapper>
      <Pagination
        itemsCountPerPage={10}
        totalItemsCount={totalItems}
        pageRangeDisplayed={3}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
Table.defaultProps = {
  title: '',
  headers: [],
  headerOptions: '',
  defaultSortOptions: {},
  children: '',
  minWidth: 0,
  totalItems: 0,
  filterComponents: '',
  onChange: () => {},
  onFilterToggle: () => {},
}

// Proptypes Validation
Table.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.instanceOf(Array),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  defaultSortOptions: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  onChange: PropTypes.func,
  onFilterToggle: PropTypes.func,
}

export default Table
