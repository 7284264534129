import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import {
  useFetchAutoCompleteCustomer,
  useAssignMonitoringTransaction,
  useAssignWhitelistingAddress,
} from 'core/hooks/api'

// Styled Elements
import { AssignToCaseFormWrapper, FormTitle } from './AssignToCaseForm.elements'

// Views
import { Form, FormTextField, FormAutoComplete } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const AssignToCaseForm = (props) => {
  // Destructure
  const { formRef, form, actions } = props

  // Variables
  const initialValues = {
    caseType: '',
    caseStatus: '',
    caseSince: '',
  }

  const validationSchema = Yup.object().shape({})

  // Store State
  const { addressOrTransactionIdToAssign } = form

  // Store Actions
  const { setShowHeaderLoader, closeAssignToCaseDrawer, showAlert } = actions

  // States
  const [formValues, setFormValues] = useState(initialValues)
  const [customerSearchOptions, setCustomerSearchOptions] = useState([])
  const [searchedCustomerList, setSearchedCustomerList] = useState([])

  // Hooks
  const { autoCompleteCustomerData, getAutoCompleteCustomer } = useFetchAutoCompleteCustomer()
  const {
    assignMonitoringTransaction,
    isAssignMonitoringTransactionLoading,
    isAssignMonitoringTransactionSuccess,
  } = useAssignMonitoringTransaction()
  const {
    assignWhitelistingAddress,
    isAssignWhitelistingAddressLoading,
    isAssignWhitelistingAddressSuccess,
  } = useAssignWhitelistingAddress()

  // Functions
  const handleOnSubmit = (values) => {
    const { caseId, addressOrTransactionId, addressOrTransactionType } = values

    const payload = {
      addressOrTransactionId,
      case_id: caseId,
    }

    if (addressOrTransactionType === 'transaction') {
      assignMonitoringTransaction(payload)
    } else if (addressOrTransactionType === 'address') {
      assignWhitelistingAddress(payload)
    }
  }

  const handleOnAutoCompleteSelect = (event) => {
    const customerID = event.target.value

    const customerData = searchedCustomerList.filter(({ Customer_ID }) => Customer_ID === customerID)[0]

    if (customerData) {
      setFormValues({
        addressOrTransactionType: addressOrTransactionIdToAssign.type,
        addressOrTransactionId: addressOrTransactionIdToAssign.id,
        caseId: customerData.Case_ID,
        caseType: customerData.Customer_ID,
        caseStatus: customerData.Case_Status,
        caseSince: customerData.Case_Since,
      })
    }
  }

  const handleOnAutoCompleteInputChange = debounce((e) => {
    if (e) {
      const queryValue = e.target.value

      if (queryValue) getAutoCompleteCustomer({ customer_id: queryValue })
    }
  }, 500)

  // useEffect
  useEffect(() => {
    if (autoCompleteCustomerData) {
      setSearchedCustomerList(() => autoCompleteCustomerData)
      setCustomerSearchOptions(() =>
        autoCompleteCustomerData.map(({ Customer_ID }) => ({ label: Customer_ID, value: Customer_ID }))
      )
    }
  }, [autoCompleteCustomerData])

  useEffect(() => {
    if (isAssignMonitoringTransactionLoading || isAssignWhitelistingAddressLoading) {
      setShowHeaderLoader(true)
    } else if (isAssignMonitoringTransactionSuccess || isAssignWhitelistingAddressSuccess) {
      setShowHeaderLoader(false)
      closeAssignToCaseDrawer()
      showAlert({ type: 'success', message: 'Successfully assigned to case' })
    }
  }, [isAssignMonitoringTransactionLoading, isAssignWhitelistingAddressLoading])

  return (
    <AssignToCaseFormWrapper>
      <FormTitle>Search Customer ID</FormTitle>
      <Form
        enableReinitialize
        formRef={formRef}
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormAutoComplete
          disableClearable
          name="customerId"
          placeholder="Type to search"
          options={customerSearchOptions}
          getOptionSelected={(option) => option.value}
          getOptionLabel={(option) => option.label || ''}
          onInputChange={handleOnAutoCompleteInputChange}
          onSelect={handleOnAutoCompleteSelect}
        />
        <br />
        <FormTextField placeholder="Type" type="text" name="caseType" disabled />
        <br />
        <FormTextField placeholder="Status" type="text" name="caseStatus" disabled />
        <br />
        <FormTextField placeholder="Case Since" type="text" name="caseSince" disabled />
      </Form>
    </AssignToCaseFormWrapper>
  )
}

// Default Props
AssignToCaseForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
AssignToCaseForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({ addressOrTransactionIdToAssign: PropTypes.instanceOf(Object) }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    closeAssignToCaseDrawer: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignToCaseForm)
