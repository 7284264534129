import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

// Assets
import EntityIcon from 'assets/images/icon-entity.svg'
import IndividualIcon from 'assets/images/icon-individual.svg'
import LinkIcon from 'assets/images/icon-link.svg'

// Hooks
import { useFetchCaseActivities } from 'core/hooks/api'

// Styled Elements
import { TableDataWrapper, TableDataItem } from './CaseManagementTableItem.elements'

// Views
import { TableItem, Image } from 'views/components'

import { CaseManagementTableChart } from 'views/layouts'
import { NavLink } from 'react-router-dom'

const CaseManagementTableItem = (props) => {
  // Destructure
  const { caseId, caseType, caseStatus, customerId, transactionCount, addressCount } = props

  // State
  const [transactionChartData, setTransactionChartData] = useState()
  const [addressChartData, setAddressChartData] = useState()

  // Hooks
  const { caseActivitiesData, getCaseActivities } = useFetchCaseActivities()

  // Functions
  const formatLineChartData = (data) => {
    const days = []
    let currentDate = null
    let stopDate = null

    const x = moment().subtract(30, 'days').format('YYYY-MM-DD')
    const y = moment().format('YYYY-MM-DD')

    currentDate = moment(x)
    stopDate = moment(y)

    while (currentDate <= stopDate) {
      days.push({ t: moment(currentDate).format('YYYY MMMM DD') })
      currentDate = moment(currentDate).add(1, 'days')
    }

    const copyArr = days

    if (data.constructor === Array) {
      data.forEach((result) => {
        copyArr.forEach((item, i) => {
          if (result.t === item.t) {
            days[i].y = result.y
          }
        })
      })
    }

    days.forEach((item, i) => {
      if (item.y === undefined) {
        days[i].y = 0
      }
    })

    return days
  }

  // useEffect
  useEffect(() => {
    getCaseActivities({ case_id: caseId, start_range: '2020-12-28', end_range: '2021-01-27' })
  }, [])

  useEffect(() => {
    if (caseActivitiesData) {
      // Set Transaction Chart Data
      setTransactionChartData({
        datasets: [
          {
            data: formatLineChartData(caseActivitiesData[0]),
            backgroundColor: 'transparent',
            borderColor: '#606167',
            borderWidth: '2',
            pointRadius: 0,
            pointHoverRadius: 0,
          },
        ],
        labels: [],
      })

      // Set Address Chart Data
      setAddressChartData({
        datasets: [
          {
            data: formatLineChartData(caseActivitiesData[1]),
            backgroundColor: 'transparent',
            borderColor: '#606167',
            borderWidth: '2',
            pointRadius: 0,
            pointHoverRadius: 0,
          },
        ],
        labels: [],
      })
    }
  }, [caseActivitiesData])

  return (
    <TableItem
      key={uuid()}
      startIcon={
        caseType === 'Legal Person' ? (
          <Image height={30} width={30} src={EntityIcon} />
        ) : (
          <Image height={30} width={30} src={IndividualIcon} />
        )
      }
      endIcon={
        <NavLink to={`/case-management/details/${customerId}`}>
          <Image height={18} width={18} src={LinkIcon} />
        </NavLink>
      }
    >
      <TableDataWrapper>
        <TableDataItem width={130}>{caseType}</TableDataItem>
        <TableDataItem width={120}>{customerId}</TableDataItem>
        <TableDataItem width={120}>{caseStatus}</TableDataItem>
        <TableDataItem>
          <CaseManagementTableChart data={transactionChartData} totalCount={transactionCount} />
        </TableDataItem>
        <TableDataItem>
          <CaseManagementTableChart data={addressChartData} totalCount={addressCount} />
        </TableDataItem>
      </TableDataWrapper>
    </TableItem>
  )
}

// Default Props
CaseManagementTableItem.defaultProps = {
  caseId: '',
  caseType: '',
  caseStatus: '',
  customerId: '',
  transactionCount: 0,
  addressCount: 0,
}

// Proptypes Validation
CaseManagementTableItem.propTypes = {
  caseId: PropTypes.string,
  caseType: PropTypes.string,
  caseStatus: PropTypes.string,
  customerId: PropTypes.string,
  transactionCount: PropTypes.number,
  addressCount: PropTypes.number,
}

export default CaseManagementTableItem
