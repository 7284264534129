import styled from 'styled-components'

export const DataBreakdownTableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const DataBreakdownTableHeader = styled.thead`
  // Themed Properties
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkGray}`};

  // Conditional Properties
  ${({ withHeader }) => !withHeader && 'display: none;'}
`

export const DataBreakdownTableHeaderRow = styled.tr`
  // Themed Properties
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkGray}`};
`

export const DataBreakdownTableHeaderItem = styled.th`
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;

  text-align: right;
  -webkit-font-smoothing: antialiased;

  // TODO: Use theme functions
  &:first-of-type {
    text-align: left;
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const DataBreakdownTableBody = styled.tbody`
  // Conditional Properties
  ${({ withData }) => !withData && 'display: none;'}
`

export const DataBreakdownTableRow = styled.tr`
  padding: 8px 0px;

  // Themed Properties
  border-bottom: ${({ theme }) => `1px dotted ${theme.colors.darkGray}`};
`

export const DataBreakdownTableRowData = styled.td`
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;

  text-align: right;
  -webkit-font-smoothing: antialiased;

  // TODO: Use theme functions
  &:first-of-type {
    text-align: left;
    font-weight: 100;
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`
