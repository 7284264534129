import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Store
import { actions } from 'core/store'

// Assets
import KeyIcon from 'assets/images/icon-2.svg'
import WaveIcon from 'assets/images/icon-1.svg'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

import {
  useFetchWhitelistingPagedTable,
  useFetchWhitelistingRiskBucket,
  useFetchWhitelistingAddressHistory,
} from 'core/hooks/api'

// Styled Elements
import {
  WhiteListingWrapper,
  WhiteListingTableWrapper,
  WhiteListingChartWrapper,
  RiskScoreChartWrapper,
  ActivityChartWrapper,
} from './Whitelisting.elements'

// Views
import { Container, Image } from 'views/components'
import { useSearchwhitelisting } from '../../../core/hooks/api'

import { App, Header, Sidebar, Content, SearchBar, RiskScoreChart, ActivityChart, AddressesTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const WhiteListing = (props) => {
  const { searchWhitelisting } = useSearchwhitelisting()
  const accessToken = JSON.parse(localStorage.getItem('userCredentials'))
  const [value, setValue] = useState('')
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  // Destructure
  const { actions } = props

  // Store Actions
  const { setShowHeaderLoader } = actions

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const {
    whitelistingPagedTableData,
    getWhitelistingPagedTable,
    isWhitelistingPagedTableLoading,
  } = useFetchWhitelistingPagedTable()
  const {
    whitelistingRiskBucketData,
    getWhitelistingRiskBucket,
    isWhitelistingRiskBucketLoading,
  } = useFetchWhitelistingRiskBucket()
  const {
    whitelistingAddressHistoryData,
    getWhitelistingAddressHistory,
    isWhitelistingAddressHistoryLoading,
  } = useFetchWhitelistingAddressHistory()

  // Functions
  const handleOnTableChange = (params) => {
    const { end, pageIndex, pageSize, sortBy, sortDirection, start } = params

    getWhitelistingPagedTable({
      biz_id: userCredentials.Business_ID,
      end_range: end,
      start_range: start,
      sortby: sortBy,
      sortdir: sortDirection,
      pageindex: pageIndex,
      pagesize: pageSize,
    })

    getWhitelistingRiskBucket({
      biz_id: userCredentials.Business_ID,
      start_range: start,
      end_range: end,
    })

    getWhitelistingAddressHistory({
      biz_id: userCredentials.Business_ID,
      start_range: start,
      end_range: end,
    })
  }

  // useEffects
  useEffect(() => {
    getWhitelistingPagedTable({
      biz_id: userCredentials.Business_ID,
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      sortby: 'CreateTimestamp',
      sortdir: 'DESC',
      pageindex: 0,
      pagesize: 10,
    })

    getWhitelistingRiskBucket({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })

    getWhitelistingAddressHistory({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })
  }, [])

  // useEffect
  useEffect(() => {
    if (isWhitelistingPagedTableLoading && isWhitelistingRiskBucketLoading && isWhitelistingAddressHistoryLoading) {
      setShowHeaderLoader(true)
    } else {
      setShowHeaderLoader(false)
    }
  }, [isWhitelistingPagedTableLoading, isWhitelistingRiskBucketLoading, isWhitelistingAddressHistoryLoading])

  const handleSubmit = (value) => {
    if (value === '') {
      setSearchErrorMessage(
        'Incorrect or unsupported asset. Please review and submit again.'
      );
    }
    searchWhitelisting({ user_id: accessToken.User_ID, address: value })
    ///const data = whitelistingPagedTableData;
    getWhitelistingPagedTable({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      sortby: 'CreateTimestamp',
      sortdir: 'DESC',
      pageindex: 0,
      pagesize: 10,
    })
    getWhitelistingRiskBucket({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })

    getWhitelistingAddressHistory({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })

    //console.log("filter",data && data.items.filter(searchvalue => searchvalue.Address === value))
    setValue(value)
  }

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <WhiteListingWrapper>
            <SearchBar searchErrorMessage={searchErrorMessage} handleSubmit={handleSubmit} />
            <Container>
              <WhiteListingChartWrapper>
                <RiskScoreChartWrapper>
                  <RiskScoreChart
                    titleIcon={<Image src={KeyIcon} />}
                    data={whitelistingRiskBucketData && whitelistingRiskBucketData.chart}
                    summaryValue={whitelistingRiskBucketData && whitelistingRiskBucketData.totalValue}
                    summaryText="Total Addresses"
                    title="Address Distribution by Risk Score"
                  />
                </RiskScoreChartWrapper>
                <ActivityChartWrapper>
                  <ActivityChart
                    titleIcon={<Image src={WaveIcon} />}
                    data={whitelistingAddressHistoryData && whitelistingAddressHistoryData}
                    title="Daily Whitelisting Activity"
                  />
                </ActivityChartWrapper>
              </WhiteListingChartWrapper>
            </Container>
            <Container>
              <WhiteListingTableWrapper>
                <AddressesTable data={whitelistingPagedTableData} onChange={handleOnTableChange} />
              </WhiteListingTableWrapper>
            </Container>
          </WhiteListingWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
WhiteListing.defaultProps = { actions: {} }

// Proptypes Validation
WhiteListing.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(WhiteListing)
