import styled from 'styled-components'

// Library Components
import { Autocomplete } from '@material-ui/lab'

export const AutoCompleteWrapper = styled.div`
  width: 100%;
`

export const Label = styled.label``

export const AutoCompleteInput = styled(Autocomplete)`
  padding: 0px;

  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
`
