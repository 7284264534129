import React from 'react'
import * as Yup from 'yup'

// Styled Elements
import { SearchBarWrapper, SearchInputWrapper, Errormessage } from './SearchBar.elements'

// Views
import { Form, Container, SearchInput } from 'views/components'

const SearchBar = ({ searchError, handleSubmit, searchErrorMessage }) => {
  // Variables
  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email Format').required('Required'),
    password: Yup.string().required('Password is required'),
  })

  // Functions
  const handleOnSubmit = (values) => { }

  return (
    <SearchBarWrapper>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        <Container>
          <SearchInputWrapper searchError={searchError} >
            <SearchInput handleSubmit={handleSubmit} className={searchErrorMessage} />
            {searchErrorMessage && (
              <Errormessage>{searchErrorMessage}</Errormessage>
            )}
          </SearchInputWrapper>
        </Container>
      </Form>
    </SearchBarWrapper >
  )
}

// Default Props
SearchBar.defaultProps = {}

// Proptypes Validation
SearchBar.propTypes = {}

export default SearchBar
