// Route Components
import {
  Login,
  ResetPassword,
  NotFound,
  UserProfile,
  Cases,
  WhiteListing,
  Monitoring,
  CasesAddresses,
  CasesDetails,
  CasesTransactions,
  Investigation,
} from 'views/pages'

export default [
  // Home
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: Cases,
      path: '/',
    },
  },
  // Case Management Details
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: CasesDetails,
      path: '/case-management/details/:customerId',
    },
  },
  // Case Management Addresses
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: CasesAddresses,
      path: '/case-management/addresses/:customerId',
    },
  },
  // Case Management Transactions
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: CasesTransactions,
      path: '/case-management/transactions/:customerId',
    },
  },
  // WhiteListing
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: WhiteListing,
      path: '/whitelisting',
    },
  },
  // Monitoring
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: Monitoring,
      path: '/monitoring',
    },
  },
  // User Profile
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: UserProfile,
      path: '/user-profile',
    },
  },
  // Investigation
  {
    exact: true,
    isRouteProtected: true,
    routing: {
      component: Investigation,
      path: '/investigation/:hash',
    },
  },
  // Login
  { isRouteProtected: false, exact: true, routing: { component: Login, path: '/login' } },
  // Reset Password
  { isRouteProtected: true, exact: true, routing: { component: ResetPassword, path: '/reset-password' } },
  { exact: false, routing: { component: NotFound } },
]
