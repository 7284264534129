import styled from 'styled-components'

// Library Components
import { NavLink } from 'react-router-dom'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  }
`

export const ModalBodyWrapper = styled.div`
  font-size: 14.5px;
  font-weight: 400;
  padding: 10px 0px 32px 0px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
  & button{
    background-color: #F8F9FA;
    font-size: 16px;
    color: #393E46;
    font-weight: 400;
  }
`
export const ModalFooterButtonSubmit = styled.div`
  display: block;
  & button {
    color: #fff !important;
    background-color: #412ed4 !important;
  }
`

export const ModalFooterButtonSubmitDis = styled.div`
  display: block;
  opacity: 0.3;
`

export const UploadFileWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  width: 100%;
  min-height: 219px;
  outline: none;
  margin: 0;
`

export const UploadFileText = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin: 0px;

  // TODO: Use theme
  color: #606167;
`

export const UploadFileSubtext = styled.p`
  font-weight: 300;
  font-size: 14px;
  margin: 0px 0px 0px 10px;

  // TODO: Use theme
  color: #606167;
`

export const UploadFileInfoWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`

export const UploadFileInfoItem = styled.div``

export const UploadFileInfoItems = styled.div``

export const UploadFileInfoItemHeader = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #5f6267;
`

export const UploadFilePath = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 200;
  color: #6b6868;
`

export const UploadFileInfoItemText = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 300;
  color: #5f6267;
  padding-right: 5px;
`

export const UploadFileInfoDownload = styled.span`
  font-weight: 400;
  cursor: pointer;

  // Themed Properties
  color: ${({ theme }) => theme.colors.blue};
`

export const UploadFileInfoLink = styled.a`
  font-weight: 400;
  cursor: pointer;

  // TODO: Use themed functions
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.blue};
`
export const ErrorMessage = styled.div`
  color: red;
  background-color: #ffd7d78a;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
`
export const ErrorMessageBack = styled.div`
margin-bottom:10px;
`
