import React from 'react'

// Constants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import Logo from 'assets/images/brand-logo-white.png'
import ChevronRight from 'assets/images/chevrons-right.svg'

// Styled Elements
import {
  LoginWrapper,
  LoginInnerWrapper,
  LoginHeaderWrapper,
  LoginLogoLink,
  LoginContentWrapper,
  LoginArticleWrapper,
  LoginBrandTitle,
  LoginBrandText,
  ReadMoreLink,
  ReadMoreLinkLogo,
  LoginFormWrapper,
  LoginFormInnerWrapper,
  LoginFormContent,
  LoginFormTitle,
  LoginFormDescription,
  LoginFormFooterText,
  LoginFormFooterLink,
  PrivacyPolicyLink,
} from './Login.elements'

// Components
import { ContainerFluid, Image } from 'views/components'
import { LoginForm } from 'views/layouts'

const Login = () => (
  <LoginWrapper>
    <ContainerFluid>
      <LoginInnerWrapper>
        <LoginContentWrapper>
          <LoginHeaderWrapper>
            <LoginLogoLink>
              <Image src={Logo} width={183} />
            </LoginLogoLink>
            <LoginArticleWrapper>
              <LoginBrandTitle>OSPREE PLATFORM</LoginBrandTitle>
              <LoginBrandText>Tested and proven, ready to use on day one.</LoginBrandText>
              <ReadMoreLink href={EXTERNAL_URLS.MAIN_SITE}>
                Read more
                <ReadMoreLinkLogo src={ChevronRight} alt="right chevrons" />
              </ReadMoreLink>
            </LoginArticleWrapper>
          </LoginHeaderWrapper>
        </LoginContentWrapper>
        <LoginFormWrapper>
          <LoginFormInnerWrapper>
            <LoginFormContent>
              <LoginFormTitle>Welcome to Ospree</LoginFormTitle>
              <LoginFormDescription>Login to your account</LoginFormDescription>
              <LoginForm />
              <LoginFormFooterText>
                Don&apos;t have an account?&nbsp;&nbsp;
                <LoginFormFooterLink href={EXTERNAL_URLS.GET_STARTED}>Start a free trial today.</LoginFormFooterLink>
              </LoginFormFooterText>
            </LoginFormContent>
            <PrivacyPolicyLink href={EXTERNAL_URLS.PRIVACY_POLICY}>Privacy Policy • Terms of Use</PrivacyPolicyLink>
          </LoginFormInnerWrapper>
        </LoginFormWrapper>
      </LoginInnerWrapper>
    </ContainerFluid>
  </LoginWrapper>
)

// Default Props
Login.defaultProps = {}

// Proptypes Validation
Login.propTypes = {}

export default Login
