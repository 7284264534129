import React from 'react'
import PropTypes from 'prop-types'

// Views
import { Card, BarChart } from 'views/components'

// Styled Elements
import { ActivityChartWrapper, BarChartWrapper } from './ActivityChart.elements'

const ActivityChart = (props) => {
  // Destructure
  const { title, titleIcon, data } = props

  return (
    <ActivityChartWrapper>
      <Card title={title} titleIcon={titleIcon}>
        <BarChartWrapper>
          <BarChart
            data={data}
            redraw
            options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                position: 'top',
                align: 'end',
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      display: false,
                    },
                    type: 'time',
                    position: 'bottom',
                    time: {
                      displayFormats: {
                        week: 'MMM D',
                      },
                      tooltipFormat: 'YYYY-MM-DD',
                      unit: 'week',
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      color: '#CED4DA',
                      drawBorder: false,
                    },

                    ticks: {
                      min: 0,
                      suggestedMax: 100,
                      padding: 5,
                      stepSize: 25,
                    },
                  },
                ],
              },
            }}
          />
        </BarChartWrapper>
      </Card>
    </ActivityChartWrapper>
  )
}

// Default Props
ActivityChart.defaultProps = {
  title: '',
  titleIcon: '',
  data: {
    labels: [],
    datasets: [],
  },
}

// Proptypes Validation
ActivityChart.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  data: PropTypes.shape({
    labels: PropTypes.instanceOf(Array),
    datasets: PropTypes.instanceOf(Array),
  }),
}

export default ActivityChart
