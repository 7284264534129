import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'
import JumioLogo from 'assets/images/logo-jumio.png'
import OnfidoLogo from 'assets/images/logo-onfido.png'
import AcurisLogo from 'assets/images/logo-acuris.png'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalBodyWrapper, UploadOptions, UploadOptionItem, BackOptionItem} from './ChooseKYCUploadModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, Image } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const ChooseKYCUploadModal = (props) => {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isChooseKYCUploadModalOpen } = ui
  // Store Actions
  const { toggleChooseKYCUploadModal, toggleUploadCaseModal } = actions

  // Functions
  const handleToggleChooseKYCUploadModal = () => {
    toggleChooseKYCUploadModal()
  }

  const handleToggleUploadCaseModal = () => {
    toggleUploadCaseModal()
  }

  return (
    <Modal hideBackdrop={true} isOpen={isChooseKYCUploadModalOpen} toggle={handleToggleChooseKYCUploadModal}>
      <ModalHeader
        toggle={handleToggleChooseKYCUploadModal}
        // close={<Image width={20} height={20} src={CloseIcon} onClick={handleToggleChooseKYCUploadModal} />}
      >
        Choose your Know Your Customer (KYC) solution
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>
          <UploadOptions>
            <UploadOptionItem>
              <Image src={JumioLogo} />
            </UploadOptionItem>
            <UploadOptionItem>
              <Image src={OnfidoLogo} />
            </UploadOptionItem>
            <UploadOptionItem>
            <Image src={AcurisLogo} />
            </UploadOptionItem>
          </UploadOptions>
          <UploadOptions>
            <UploadOptionItem onClick={handleToggleUploadCaseModal}>
              <p>My KYC solution is not in this list</p>
            </UploadOptionItem>
          </UploadOptions>
        </ModalBodyWrapper>
      </ModalBody>
      <BackOptionItem>
                <button onClick={handleToggleChooseKYCUploadModal} className="btn-upload">
                  Back
                </button>
              </BackOptionItem>
    </Modal>
  )
}

// Default Props
ChooseKYCUploadModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
ChooseKYCUploadModal.propTypes = {
  ui: PropTypes.shape({ isChooseKYCUploadModalOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    toggleChooseKYCUploadModal: PropTypes.func,
    toggleUploadCaseModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseKYCUploadModal)
