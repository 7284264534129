import styled from 'styled-components'

export const TextBlurWrapper = styled.div`
  height: 19px;
  margin-left: 1px;
  margin-right: 1px;
  width: 12px;
  background-color: #e4e5e6;
  font-weight: inherit;
  font-size: inherit;

  // Conditional Properties
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`};
  ${({ color }) => color && `background-color: ${color};`};
`
