import styled from 'styled-components'

import { Layout } from 'antd'

const { Content } = Layout

export const ContentWrapper = styled(Content)`
  padding: 0px 0px 100px 0px;
  width: 100%;
  height: 100%;
  background-color: #f8f9fb;
`
