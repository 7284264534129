import styled from 'styled-components'

// Library Components
import Pagination from 'react-js-pagination'

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 0;

  // Overrides
  & ul {
    & li {
      margin: 0 11px;
      color: #dfe0e2;

      &:first-child,
      &:last-child {
        display: none;
      }
    }

    & .active {
      margin: 0 2px;
      padding: 0 7px;

      font-size: 15px;
      font-weight: 400;

      border-radius: 3px;
      border: 1px solid #72757b;
      background: #e9e9e9;

      a {
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
        color: #464952;
      }
    }
  }
`

export const PaginationNavigation = styled(Pagination)``

export const PaginationRangeSelectorWrapper = styled.div`
  width: 100%;
  max-width: 140px;

  // TODO: Use theme function
  &:after {
    color: #393e46;
  }
`

export const PaginationRangeSelector = styled.select`
  padding: 0 10px;
  width: 140px;

  font-size: 16px;
  font-weight: 400;

  border: none;

  color: #393e46;

  background: transparent;

  // TODO: Use theme function
  &:focus {
    outline: none;
  }
`

export const PaginationRangeSelectorOption = styled.option`
  font-size: 15px;
  font-weight: 200;
  padding: 2px 5px;
  background-color: #fff;
  box-shadow: 5px 10px #888888;
  color: #000000;
`
