import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;

  overflow: hidden;

  font-size: 18px;

  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
`

export const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  border-radius: 5px 5px 0 0;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const HeaderTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  width: fit-content;
`

export const HeaderIcon = styled.div`
  width: 32px;
  height: 32px;
`

export const HeaderTitle = styled.div`
  margin: 0px 0px 0px 10px;
`

export const HeaderFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

export const HeaderOptionsWrapper = styled.div`
  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['none', 'none', 'none', 'none', 'none', 'block'])}
`

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  height: 0px;
  width: 100%;
  padding: 0px 20px;
  border-bottom: none;

  transition: all 0.1s ease-in;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['none', 'none', 'none', 'none', 'none', 'flex'])}

  // Conditional Properties
  ${({ collapseFilter, withFilter }) => !collapseFilter && withFilter && 'height: auto;'}
  ${({ collapseFilter, withFilter }) => !collapseFilter && withFilter && 'padding: 20px;'}
  ${({ theme, collapseFilter, withFilter }) =>
    !collapseFilter && withFilter && `border-bottom: 1px solid ${theme.colors.lightGrey}`}
    
`

export const TableBodyOverflowWrapper = styled.div`
  overflow-x: auto;
`

export const TableBodyWrapper = styled.div`
  // Conditional Properties
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
`

export const TableBodyHeaderWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 20px 50px;

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

  // Conditional Properties
  ${({ showHeader }) => showHeader && 'display: flex;'}
`

export const TableBodyHeaderItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  text-align: center;
  font-weight: 400;
  font-size: 15px;
  font-family: Roboto;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};

  // Conditional Properties
  ${({ sortable }) => sortable && 'cursor: pointer;'}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`

export const TableBodyHeaderText = styled.div``

export const TableBodySubHeaderText = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 300;
`

export const TableBodyHeaderIcon = styled.span`
  display: none;

  margin: 0px 0px 0px 6px;
  transition: all 0.1s ease-in;

  // Conditional Properties
  ${({ active }) => active && 'display: flex;'}
  ${({ sortDirection }) => sortDirection === 'DESC' && 'transform: rotate(180deg);'}
`
