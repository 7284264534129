import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

import { Layout } from 'antd'

const { Header } = Layout

export const HeaderWrapper = styled(Header).attrs(({ theme }) => ({
  display: theme.layout.header || theme.layout.header === undefined ? 'block' : 'none',
}))`
  position: fixed;
  padding: 0px;
  z-index: 9;
  height: fit-content;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid lightgray;
  line-height: unset;
  font-family: Roboto;
  font-weight: 500;

  // Themed Properties
  display: ${({ display }) => display};
`

export const HeaderInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  line-height: unset;
  min-height: 72px;
`

export const HeaderBrand = styled.div`
  padding: 0px 40px 0px 0px;
  text-align: left;
`

export const HeaderBrandLogo = styled.div`
  width: 90px;
`

export const HeaderMenuNav = styled.div`
  flex: 1;
  height: 100%;

  ${({ theme }) => theme.functions.mediaQuery('width', ['500px', '500px', '500px', '500px', '500px', '580px'])}
  ${({ theme }) => theme.functions.mediaQuery('display', ['none', 'none', 'none', 'none', 'none', 'none', 'unset'])}
`

export const HeaderMenuNavList = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  list-style: none;
`

export const HeaderMenuNavItem = styled.div`
  padding: 0px;
  align-items: center;
  height: 100%;
  transition: 0.1s ease-in;
`

export const HeaderMenuNavLink = styled(NavLink)`
  display: block;
  align-items: center;
  font-size: 14px;
  color: #31353e;
  text-decoration: none;
  white-space: nowrap;
  background-color: #fff;

  // Media Query Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', [
      '25px 125px',
      '25px 125px',
      '25px 125px',
      '25px 125px',
      '25px 125px',
      '25px 32px',
    ])}

  // TODO: Use Theme Mixins
  &:hover {
    background-color: #f8f9fa;
  }

  &.active {
    font-weight: 700;
    background-color: #e9e9e9;

    &:hover {
      background-color: #f8f9fa;
    }
  }
`

export const HeaderRightNav = styled.div`
  padding: 0px;
  text-align: right;

  // Media Query Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['none', 'none', 'none', 'none', 'none', 'none', 'block'])}
`

export const HeaderRightNavList = styled.ul`
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: fit-content;
  list-style: none;
`

export const HeaderRightNavListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  width: fit-content;
  margin: 0px 0px 0px 12px;
`

export const HeaderLogoutButton = styled.div`
  cursor: pointer;

  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;
`

export const DropdownItemLink = styled(NavLink)`
  display: flex;
  align-items: center;

  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;
`

export const ProfileInfoWrapper = styled.div`
  padding: 0.7rem 1.5rem;
  border-bottom: 1px solid #d9d9d9;
`

export const ProfileFullName = styled.div`
  font-size: 21px;
  margin-bottom: 2px;
  font-weight: 500;
  line-height: 1.2;
`

export const ProfileUserName = styled.div`
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 400;
`

export const ProfileUserRole = styled.div`
  font-size: 15px;
  font-weight: 400;
`

export const HeaderSideMenuButton = styled.div`
  width: 100%;
  padding: 0px;
  text-align: right;

  // Media Query Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('display', ['block', 'block', 'block', 'block', 'block', 'block', 'none'])}
`

export const HeaderSideMenuIcon = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 28px;
  height: 100%;
`

export const NotificationIconImage = styled.div`
  width: 22px;
  height: 100%;
`
export const NotificationBell = styled.div`
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
  font-size: 8px;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid white;
  display:block;
`;

export const UserIconImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 32px;
  height: 32px;
  border: 1.5px solid #606167;
  border-radius: 100%;
`

export const MenuIconImage = styled.div`
  width: 4px;
  height: 100%;
`

export const LogoutIconImage = styled.div`
  display: inline-block;
  margin: 0px 10px 0px 0px;
`

export const UserCircledIconImage = styled.div`
  margin: 0px 10px 0px 0px;
`

export const SubHeaderWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const SubHeaderContent = styled.div`
  max-width: 1640px;
`
export const FileText = styled.div`
  & span{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 195px;
    font-size: 15px;
    padding: 0.7rem 1.5rem;
    border-top: 1px solid #e5e5e5;
    margin: 0px -20px -8px -20px;
    margin-top: 9px;
    :hover{
      background-color:#f5f5f5;
    }
  }
`;

export const FileWrapper = styled.div`
  diplay:block;
  & button {
    border: none;
    background: transparent;
    text-align: left;
    margin: -8px -20px -9px -20px;
    width: 195px;
    font-size: 15px;
    padding: 0.7rem 1.5rem;
    :hover{
      background-color:#f5f5f5;
    }
    & p{
      margin: 0;
      font-size: 15px;
    }
  }
`;