import React, { useEffect,useContext } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Context } from 'common/context/Context';


// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateCase, useFetchAllCaseCountries } from 'core/hooks/api'

// Styled Elements
import { CreateEntityCaseFormWrapper, FormTitle } from './CreateEntityCaseForm.elements'

// Views
import { Form, FormTextField, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const CreateEntityCaseForm = (props) => {
  const {
    updateData,
  } = useContext(Context);
  // Destructure
  const { formRef, form, actions } = props

  // Store State
  const { countryListOptions } = form

  // Store Actions
  const { setCountryListOptions } = actions

  // Variables
  const initialValues = {
    customerId: '',
    companyName: '',
    incorporationCountry: '',
    companyAddress: '',
  }

  const validationSchema = Yup.object().shape({
    customerId: Yup.string().required('Customer Id is required'),
    companyName: Yup.string().required('Customer Name is required'),
    incorporationCountry: Yup.string().required('Incorporation Name is required'),
    companyAddress: Yup.string().required('Company Address is required'),
  })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { allCaseCountriesData, getAllCaseCountries } = useFetchAllCaseCountries()
  const { createCase } = useCreateCase()

  // Functions
  const handleOnSubmit = (values) => {
    const { customerId, companyName, incorporationCountry, companyAddress } = values

    const payload = {
      adverse_media: 0,
      biz_id: userCredentials.Business_ID,
      case_id: '',
      case_risk: 0,
      case_type: 2,
      comments: '',
      company_name: companyName,
      country_address: companyAddress,
      country_incorporation: incorporationCountry,
      customer_id: customerId,
      date_birth: '',
      date_incoportation: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      nationality: '',
      pep: 0,
      sanction: 0,
      status: 1,
    }

    createCase(payload)
    // updateData(true);
  }

  // useEffect
  useEffect(() => {
    if (!countryListOptions.length) {
      getAllCaseCountries()
    }
  }, [])

  useEffect(() => {
    if (!countryListOptions.length && allCaseCountriesData) {
      const countriesList = allCaseCountriesData.map(({ Description, Code }) => ({ value: Code, label: Description }))
      setCountryListOptions(countriesList)
    }
  }, [allCaseCountriesData])

  return (
    <CreateEntityCaseFormWrapper>
      <FormTitle>What is the Customer ID of the case you want to create?</FormTitle>
      <Form
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormTextField placeholder="Customer ID e.g. ID0001" type="text" name="customerId" />
        <br />
        <FormTextField placeholder="Company Name" type="text" name="companyName" />
        <br />
        <FormSelect placeholder="Incorporation Country" name="incorporationCountry" options={countryListOptions} />
        <br />
        <FormSelect placeholder="Company Address" name="companyAddress" options={countryListOptions} />
        <br />
      </Form>
    </CreateEntityCaseFormWrapper>
  )
}

// Default Props
CreateEntityCaseForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
CreateEntityCaseForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEntityCaseForm)
