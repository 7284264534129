import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Library Components
import { Layout } from 'antd'

// Assets
import KeyIcon from 'assets/images/icon-3.svg'
import WaveIcon from 'assets/images/icon-1.svg'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchMonitoringTransactionSearch } from '../../../core/hooks/api'

import {
  useFetchMonitoringPagedTable,
  useFetchMonitoringRiskBucket,
  useFetchMonitoringTransactionHistory,
} from 'core/hooks/api'

// Styled Elements
import {
  MonitoringWrapper,
  MonitoringTableWrapper,
  MonitoringChartWrapper,
  RiskScoreChartWrapper,
  ActivityChartWrapper,
} from './Monitoring.elements'

// Views
import { Container, Image } from 'views/components'

import {
  App,
  Header,
  Sidebar,
  Content,
  SearchBar,
  RiskScoreChart,
  ActivityChart,
  TransactionsTable,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
const Monitoring = (props) => {
  const accessToken = JSON.parse(localStorage.getItem('userCredentials'))
  const [value, setValue] = useState('')
  // Destructure
  const { actions } = props

  // Store Actions
  const { setShowHeaderLoader } = actions

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)

  //state
  const [searchErrorMessage, setSearchErrorMessage] = useState('')
  const {
    monitoringPagedTableData,
    getMonitoringPagedTable,
    isMonitoringPagedTableLoading,
  } = useFetchMonitoringPagedTable()
  const {
    monitoringRiskBucketData,
    getMonitoringRiskBucket,
    isMonitoringRiskBucketLoading,
  } = useFetchMonitoringRiskBucket()
  const {
    monitoringTransactionHistoryData,
    getMonitoringTransactionHistory,
    isMonitoringTransactionHistoryLoading,
  } = useFetchMonitoringTransactionHistory()
  const { getMonitoringTransaction, monitoringSearchTransactionError } = useFetchMonitoringTransactionSearch()

  // Functions
  const handleOnTableChange = (params) => {
    const { end, pageIndex, pageSize, sortBy, sortDirection, start } = params

    getMonitoringPagedTable({
      biz_id: userCredentials.Business_ID,
      end_range: end,
      start_range: start,
      sortby: sortBy,
      sortdir: sortDirection,
      pageindex: pageIndex,
      pagesize: pageSize,
    })

    getMonitoringRiskBucket({
      biz_id: userCredentials.Business_ID,
      start_range: start,
      end_range: end,
    })

    getMonitoringTransactionHistory({
      biz_id: userCredentials.Business_ID,
      start_range: start,
      end_range: end,
    })
  }

  // useEffects
  useEffect(() => {
    getMonitoringPagedTable({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      sortby: 'CreateTimestamp',
      sortdir: 'DESC',
      pageindex: 0,
      pagesize: 10,
    })

    getMonitoringRiskBucket({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })

    getMonitoringTransactionHistory({
      biz_id: userCredentials.Business_ID,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })
  }, [])

  const handleSubmit = (value) => {
    console.log('value', value)
    if (value === '') {
      setSearchErrorMessage('Incorrect or unsupported asset. Please review and submit again.')
    }
    // if (monitoringSearchTransactionError === 'Request failed with status code 400') {
    //   setSearchErrorMessage(
    //     'Incorrect or unsupported asset. Please review and submit again.'
    //   );
    // }
    else {
      getMonitoringTransaction({ user_id: accessToken.User_ID, transaction: value })
      getMonitoringPagedTable({
        biz_id: userCredentials.Business_ID,
        end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
        start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
        sortby: 'CreateTimestamp',
        sortdir: 'DESC',
        pageindex: 0,
        pagesize: 10,
      })

      getMonitoringRiskBucket({
        biz_id: userCredentials.Business_ID,
        start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
        end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      })

      getMonitoringTransactionHistory({
        biz_id: userCredentials.Business_ID,
        start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
        end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      })

      //console.log("filter",data && data.items.filter(searchvalue => searchvalue.Address === value))
      setValue(value)
    }
  }

  // useEffect
  useEffect(() => {
    if (isMonitoringPagedTableLoading && isMonitoringRiskBucketLoading && isMonitoringTransactionHistoryLoading) {
      setShowHeaderLoader(true)
    } else {
      setShowHeaderLoader(false)
    }
  }, [isMonitoringPagedTableLoading, isMonitoringRiskBucketLoading, isMonitoringTransactionHistoryLoading])
  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <MonitoringWrapper>
            <SearchBar searchErrorMessage={searchErrorMessage} handleSubmit={handleSubmit} />

            <Container>
              <MonitoringChartWrapper>
                <RiskScoreChartWrapper>
                  <RiskScoreChart
                    titleIcon={<Image src={KeyIcon} />}
                    data={monitoringRiskBucketData.chart}
                    summaryValue={monitoringRiskBucketData.totalValue}
                    summaryText="Total Transactions"
                    title="Transaction Distribution by Risk Score"
                  />
                </RiskScoreChartWrapper>
                <ActivityChartWrapper>
                  <ActivityChart
                    titleIcon={<Image src={WaveIcon} />}
                    data={monitoringTransactionHistoryData}
                    title="Daily Transaction Monitoring Activity"
                  />
                </ActivityChartWrapper>
              </MonitoringChartWrapper>
            </Container>
            <Container>
              <MonitoringTableWrapper>
                <TransactionsTable data={monitoringPagedTableData} onChange={handleOnTableChange} />
              </MonitoringTableWrapper>
            </Container>
          </MonitoringWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Monitoring.defaultProps = { actions: {} }

// Proptypes Validation
Monitoring.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring)
