import React from 'react'

const NotFound = () => <div>404 Page Not Found</div>

// Default Props
NotFound.defaultProps = {}

// Proptypes Validation
NotFound.propTypes = {}

export default NotFound
