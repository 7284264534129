import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  font-size: 14.5px;
  font-weight: 400;
  padding: 0px 0px 20px 0px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`

export const ModalCustomeHeader = styled.div`
  display: block;
    & .modal-header{
      display: flex;
      align-items: center;
      border-bottom:none;
      & h5 > div {
        margin-left: 0;
        font-size: 20px;
        font-weight: 700;
        word-wrap: break-word;
        word-break: break-all;
        color:#393e46;
      }
    }
`;
