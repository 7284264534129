import axios from 'axios'

import { BASE_URL } from 'common/constants/apiUrls'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const authAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const publicAxios = axios.create({
  baseURL: BASE_URL,
})
