import styled from 'styled-components'

export const SearchBarWrapper = styled.div`
  position: relative;
  padding: 42px 0px;

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.ash};
  background-color: ${({ theme }) => theme.colors.white};
`

export const SearchInputWrapper = styled.div`
  position: relative;

  margin: 0 auto;
  max-width: 600px;
`
export const Errormessage = styled.p`
  font-size: 15px;
  color: #fd1e4d;
`;