import styled from 'styled-components'

// Library Components
import { Modal } from 'reactstrap'

export const ModalWrapper = styled(Modal)`
  /* border: none;
  box-shadow: none;
  border-radius: 5px; */
  padding: 20px;
  width: 100%;
  max-width: 560px !important;
`
