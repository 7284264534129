import styled from 'styled-components'

export const AccordionTableDetailsWrapper = styled.div`
  display: flex;
`

export const TransactionDetailsWrapper = styled.div`
  flex: 1.8;
`

export const AccountDetailsWrapper = styled.div`
  flex: 1;

  // TODO: Make dynamic padding
  padding: 0px 0px 0px 20px;
`

export const AccordionTableDetailsItem = styled.div`
  margin: 0px 0px 20px 0px;
`

export const AccordionTableDetailsTitle = styled.div`
  padding: 6px 0px;

  font-size: 16px;
  font-weight: 500;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`
