import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetWhitelistingPagedTable } from 'services/api/Whitelisting'

export default () => {
  const [whitelistingPagedTableData, setWhitelistingPagedTableData] = useState()

  const {
    mutate: getWhitelistingPagedTable,
    data,
    error: whitelistingPagedTableError,
    isLoading: isWhitelistingPagedTableLoading,
    isSuccess: isWhitelistingPagedTableSuccess,
    isError: isWhitelistingPagedTableError,
  } = useMutation(GetWhitelistingPagedTable)

  useEffect(() => {
    if (data) {
      setWhitelistingPagedTableData(data)
    }
  }, [data])

  return {
    getWhitelistingPagedTable,
    whitelistingPagedTableData,
    whitelistingPagedTableError,
    isWhitelistingPagedTableLoading,
    isWhitelistingPagedTableSuccess,
    isWhitelistingPagedTableError,
  }
}
