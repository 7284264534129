import React from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import { v4 as uuid } from 'uuid'

// Styled Elements
import {
  FormSelectWrapper,
  FormLabel,
  FormGroupWrapper,
  FormSelectInput,
  FormSelectOption,
  FormErrorMessage,
} from './FormSelect.elements'

const FormSelect = (props) => {
  // Destructure
  const { label, placeholder, options, ...rest } = props

  // Hooks
  const [fields] = useField(props)

  // Variables
  const withOptions = options && options?.length
  const { name, value, onChange, onBlur } = fields

  return (
    <FormSelectWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <FormGroupWrapper>
        <FormSelectInput {...rest} name={name} onChange={onChange} onBlur={onBlur} value={value || placeholder}>
          {placeholder && (
            <FormSelectOption value={placeholder} disabled hidden>
              {placeholder}
            </FormSelectOption>
          )}
          {withOptions &&
            options.map(({ value, label }) => (
              <FormSelectOption key={uuid()} value={value}>
                {label}
              </FormSelectOption>
            ))}
        </FormSelectInput>
        <FormErrorMessage>
          <ErrorMessage {...fields} />
        </FormErrorMessage>
      </FormGroupWrapper>
    </FormSelectWrapper>
  )
}

// Default Props
FormSelect.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
}

// Proptypes Validation
FormSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
}

export default FormSelect
