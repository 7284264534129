import React from 'react'
import PropTypes from 'prop-types'

// Library Components
import { TextField } from '@material-ui/core'

// Styled Elements
import { AutoCompleteWrapper, Label, AutoCompleteInput } from './AutoComplete.elements'

const AutoComplete = (props) => {
  // Destructure
  const {
    type,
    label,
    options,
    placeholder,
    variant,
    onInputChange,
    getOptionSelected,
    getOptionLabel,
    onSelect,
    ...rest
  } = props

  return (
    <AutoCompleteWrapper>
      {label && <Label>{label}</Label>}
      <AutoCompleteInput
        type={type}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onSelect={onSelect}
        onInputChange={onInputChange}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} variant={variant} />}
        {...rest}
      />
    </AutoCompleteWrapper>
  )
}

// Default Props
AutoComplete.defaultProps = {
  type: '',
  label: '',
  placeholder: '',
  variant: 'outlined',
  options: [],
  onSelect: () => {},
  onInputChange: () => {},
  getOptionSelected: () => {},
  getOptionLabel: () => {},
}

// Proptypes Validation
AutoComplete.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
}

export default AutoComplete
