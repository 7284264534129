import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import xlsx from 'xlsx'
import moment from 'moment'

// Library Components
import Dropzone from 'react-dropzone'

// Assets
import CloseIcon from 'assets/images/x.svg'
import UploadIcon from 'assets/images/icon-upload.svg'

// Contants
import { SAMPLE_UPLOAD_FILE } from 'common/constants/sampleData'
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import { Context } from 'common/context/Context'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import {
  useBatchUploadCases,
  useBatchUploadAddresses,
  useBatchUploadTransactions,
  useFetchCaseDetails,
  useFetchCaseCustomer,
  useFetchCaseAddresses,
  useFetchCaseTransactions,
} from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  ModalFooterWrapper,
  ModalBodyWrapper,
  ModalFooterButton,
  UploadFileWrapper,
  UploadFileText,
  UploadFileSubtext,
  UploadFileInfoWrapper,
  UploadFileInfoItem,
  UploadFileInfoItemHeader,
  UploadFilePath,
  UploadFileInfoItemText,
  UploadFileInfoDownload,
  UploadFileInfoLink,
  ModalFooterButtonSubmit,
  ModalFooterButtonSubmitDis,
  ErrorMessage,
  ErrorMessageBack,
} from './UploadCaseModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const UploadCaseModal = (props) => {
  const {
    updateGlobalNotifToggler,
    refreshTable,
    refreshTableData,
    dateRange,
    pageIndex,
    pageSize,
    sortBy,
    sortDir,
    updatePageIndex,
    updateSortBy,
    updateSortDir,
  } = useContext(Context)

  // Destructure
  const { ui, actions, caseId, setActiveCase } = props

  // Store State
  const { isUploadCaseModalOpen, activeModule, activeCase, activePage } = ui

  // Store Actions
  const { toggleUploadCaseModal, toggleChooseKYCUploadModal, setShowHeaderLoader } = actions

  // States
  const [uploadData, setUploadData] = useState([])
  const [caseDataLength, setCaseDataLength] = useState(null)
  const [invalidHeaders, setInvalidHeaders] = useState([])
  const [fileData, setFileData] = useState('')
  const [file, setFile] = useState(null)
  const [fileLength, setFileLength] = useState('')
  const [fileName, setFileName] = useState()

  // Hooks
  const { customerId } = useParams()
  const [userCredentials] = useLocalStorage('userCredentials')
  const { batchUploadCases, isBatchUploadCasesLoading } = useBatchUploadCases()
  const { batchUploadAddresses, isBatchUploadAddressesLoading } = useBatchUploadAddresses()
  const { batchUploadTransactions, isBatchUploadTransactionsLoading } = useBatchUploadTransactions()
  const { getCaseDetails, isCaseDetailsLoading } = useFetchCaseDetails()
  const { caseCustomerData, getCaseCustomer, isCaseCustomerLoading } = useFetchCaseCustomer()
  const { getCaseAddresses, isCaseAddressesLoading } = useFetchCaseAddresses()
  const { getCaseTransactions, isCaseTransactionsLoading } = useFetchCaseTransactions()

  // Functions
  const toggleModal = () => {
    setFile(null)
    setFileLength('')
    toggleUploadCaseModal()
    setCaseDataLength(null)
    setFileName()
  }

  useEffect(() => {
    if (customerId) {
      getCaseCustomer({ customer_id: customerId })
    }
  }, [customerId])

  useEffect(() => {
    updateSortBy('CreateTimestamp')
    updatePageIndex(1)
    updateSortDir('DESC')
  }, [])

  useEffect(() => {
    if (
      isBatchUploadCasesLoading &&
      isBatchUploadAddressesLoading &&
      isBatchUploadTransactionsLoading &&
      isCaseDetailsLoading &&
      isCaseCustomerLoading &&
      isCaseAddressesLoading &&
      isCaseTransactionsLoading
    ) {
      setShowHeaderLoader(true)
    } else {
      setShowHeaderLoader(false)
    }
  }, [
    isBatchUploadCasesLoading,
    isBatchUploadAddressesLoading,
    isBatchUploadTransactionsLoading,
    isCaseDetailsLoading,
    isCaseCustomerLoading,
    isCaseAddressesLoading,
    isCaseTransactionsLoading,
  ])

  // TODO: Refactor
  const handleOnSubmit = () => {
    localStorage.setItem('fileset', JSON.stringify(fileName))
    toggleChooseKYCUploadModal()
    if (activeModule === 'cases') {
      batchUploadCases(uploadData)
      getCaseDetails({ case_id: caseId })
      toggleModal()
      toggleChooseKYCUploadModal()
      refreshTable(true)
    } else if (activeModule === 'transactions') {
      batchUploadTransactions(uploadData)
      getCaseTransactions({
        case_id: caseCustomerData.Case_ID,
        start_range: dateRange.startDate,
        end_range: dateRange.endDate,
        sortby: sortBy,
        sortdir: sortDir,
        pageindex: pageIndex - 1,
        pagesize: pageSize,
      })
      toggleModal()
      toggleChooseKYCUploadModal()
      refreshTable(true)
    } else if (activeModule === 'addresses') {
      batchUploadAddresses(uploadData)
      getCaseAddresses({
        case_id: caseCustomerData.Case_ID,
        start_range: dateRange.startDate,
        end_range: dateRange.endDate,
        sortby: sortBy,
        sortdir: sortDir,
        pageindex: pageIndex - 1,
        pagesize: pageSize,
      })
      toggleModal()
      toggleChooseKYCUploadModal()
      refreshTable(true)
    }
    setFileData()
    updateGlobalNotifToggler(true)
    refreshTable(true)
  }

  const handleOnFileDrop = (files) => {
    let sheetEndRange = null
    if (files && files.length) {
      let DataSet = []
      files &&
        files.map((data) => {
          DataSet.push(data)
        })
      setFileName(DataSet)
      // localStorage.setItem("fileset", JSON.stringify(DataSet));
    }
    const filePath = files[0].path
    setFile(filePath)
    // const fileLengthData = files[0].size
    // setFileLength(fileLengthData)

    setFileData(files && files.length)

    if (activeModule === 'cases') {
      sheetEndRange = 8
    } else if (activeModule === 'transactions') {
      sheetEndRange = 0
    } else if (activeModule === 'addresses') {
      sheetEndRange = 0
    }

    files.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')

      reader.onload = () => {
        const binaryStr = reader.result

        const data = new Uint8Array(binaryStr)

        const arr = []
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')

        // Call xlsx
        const workbook = xlsx.read(bstr, { type: 'binary', cellDates: true })

        /* DO SOMETHING WITH workbook HERE */
        const first_sheet_name = workbook.SheetNames[0]
        /* Get worksheet */
        const worksheet = workbook.Sheets[first_sheet_name]

        let new_range = null

        try {
          const range = xlsx.utils.decode_range(workbook.Sheets[first_sheet_name]['!ref'])
          range.s.c = 0 // 0 == xlsx.utils.decode_col("A")
          range.e.c = sheetEndRange // 6 == xlsx.utils.decode_col("G")
          // range.s.c =
          new_range = xlsx.utils.encode_range(range)
        } catch (err) {
          setCaseDataLength(0)
        }

        const excelData = xlsx.utils.sheet_to_json(worksheet, {
          raw: true,
          defval: 'NULL',
          dateNF: 'yyyy-mm-dd',
          range: new_range,
        })

        if (excelData.length > 0) {
          const headers = Object.keys(excelData[0])
          const headerResult = checkHeaders(headers, activeModule)
          let cleanObject = {}
          setCaseDataLength(excelData.length)
          if (typeof headerResult === 'boolean') {
            if (activeModule === 'cases') {
              cleanObject = processExcelCaseKeys(excelData)
            } else if (activeModule === 'transactions') {
              cleanObject = processExcelTransactionKeys(excelData)
            } else if (activeModule === 'addresses') {
              cleanObject = processExcelAddressKeys(excelData)
            }

            setInvalidHeaders([])

            // CHECK DATE FORMAT
            cleanObject.forEach((item) => {
              if (!Number.isNaN(new Date(item.Date_Birth).getTime())) {
                if (moment(item.Date_Birth).format(DASHED_DATE_FORMAT) !== 'Invalid date') {
                  item.Date_Birth = moment(item.Date_Birth).format(DASHED_DATE_FORMAT)
                }
              }
              return false
            })
            setUploadData(cleanObject)
          } else {
            setInvalidHeaders(headerResult)
          }
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const processExcelCaseKeys = (excelData) => {
    excelData.forEach((data, index) => {
      excelData[index].Business_ID = userCredentials.Business_ID

      excelData[index] = renameKey(data, 'Unique Id', 'Customer_ID')
      excelData[index] = renameKey(excelData[index], 'Entity Type', 'Case_Type')
      excelData[index] = renameKey(excelData[index], 'First Name', 'First_Name')
      excelData[index] = renameKey(excelData[index], 'Last Name', 'Last_Name')
      excelData[index] = renameKey(excelData[index], 'Business Name', 'Company_Name')
      excelData[index] = renameKey(excelData[index], 'Date of Birth', 'Date_Birth')
      excelData[index] = renameKey(excelData[index], 'Country Location', 'Country_Address')
      excelData[index] = renameKey(excelData[index], 'Nationality', 'Nationality')
      excelData[index] = renameKey(excelData[index], 'Registered Country', 'Country_Incorporation')
    })

    return excelData
  }

  const processExcelAddressKeys = (excelData) => {
    excelData.forEach((data, index) => {
      if (typeof window !== 'undefined') {
        excelData[index].case_id = activeCase
        excelData[index].user_id = userCredentials.User_ID
        excelData[index] = renameKey(data, 'Address', 'address')
      }
    })

    return excelData
  }

  const processExcelTransactionKeys = (excelData) => {
    excelData.forEach((data, index) => {
      if (typeof window !== 'undefined') {
        excelData[index].case_id = activeCase
        excelData[index].user_id = userCredentials.User_ID
        excelData[index] = renameKey(data, 'Transaction', 'transaction')
      }
    })

    return excelData
  }

  const checkHeaders = (header, activeModule) => {
    let correctHeaders = []
    if (activeModule === 'cases') {
      correctHeaders = [
        'Unique Id',
        'Entity Type',
        'First Name',
        'Last Name',
        'Business Name',
        'Date of Birth',
        'Country Location',
        'Nationality',
        'Registered Country',
      ]
    } else if (activeModule === 'addresses') {
      correctHeaders = ['Address']
    } else if (activeModule === 'transaction') {
      correctHeaders = ['Transaction']
    }

    let counter = 0
    const invalid = []

    header.forEach((item) => {
      if (!correctHeaders.includes(item)) {
        invalid.push(item)
      } else {
        counter++
      }
    })

    if (counter === correctHeaders.length) {
      return true
    }

    return invalid
  }

  const clone = (obj) => ({ ...obj })

  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
  //   handleOnFileDrop,
  //   // onDropRejected,
  //   accept: '.xlsx, .xlsm, .csv, application/vnd.ms-excel, text/csv,',
  // })

  const filesData =
    fileName &&
    fileName.map((file) => (
      <UploadFilePath>
        {file['path']}-{file['size']}
        bytes
      </UploadFilePath>
    ))

  const renameKey = (object, key, newKey) => {
    const clonedObj = clone(object)
    const targetKey = clonedObj[key]
    delete clonedObj[key]
    clonedObj[newKey] = targetKey
    return clonedObj
  }

  const ImportAddress = () =>
    activePage === 'Whitelisting' && (
      <button type="button" className="btn-outline-blue opaque" onClick={toggleModal}>
        Import
      </button>
    )

  const ImportTransaction = () =>
    activePage === 'Monitoring' && (
      <button type="button" className="btn-outline-blue opaque" onClick={toggleModal}>
        Import
      </button>
    )

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const exportSampleFile = (config) => {
    const wb = xlsx.utils.book_new()
    wb.Props = {
      Title: 'Ospree',
      Subject: 'Ospree',
      Author: 'Ospree',
      CreatedDate: moment(),
    }

    wb.SheetNames.push('Sheet1')

    const ws = xlsx.utils.json_to_sheet(config.obj, {
      header: config.header,
    })

    const title = config.filename
    wb.Sheets.Sheet1 = ws
    const wbout = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' })
    saveAs(
      new Blob([s2ab(wbout)], {
        type: 'application/octet-stream',
      }),
      title
    )
  }

  const saveExportableFile = () => {
    if (activeModule === 'addresses') {
      exportSampleFile(SAMPLE_UPLOAD_FILE[0])
    } else if (activeModule === 'cases') {
      exportSampleFile(SAMPLE_UPLOAD_FILE[1])
    } else if (activeModule === 'transactions') {
      exportSampleFile(SAMPLE_UPLOAD_FILE[2])
    }
  }
  // TODO: Refactor

  return (
    <Modal hideBackdrop={false} isOpen={isUploadCaseModalOpen} toggle={toggleModal}>
      <ModalHeader
        toggle={toggleModal}
        // close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}
      >
        Upload your file
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>
          <Dropzone onDrop={handleOnFileDrop} accept=".xlsx, .xlsm, .csv, application/vnd.ms-excel, text/csv,">
            {({ getRootProps, getInputProps }) => (
              <>
                <UploadFileWrapper {...getRootProps()} style={{ outline: 'none' }}>
                  <input {...getInputProps()} />
                  <Image src={UploadIcon} width={61} height={61} />
                  <UploadFileText>Drag and drop or click here</UploadFileText>
                  <UploadFileSubtext>All .xlsx, .xlsm and .csv file types are supported.</UploadFileSubtext>
                </UploadFileWrapper>
                {filesData}
                <UploadFileInfoWrapper>
                  <UploadFileInfoItem>
                    <UploadFileInfoItemHeader>Download a sample spreadsheet</UploadFileInfoItemHeader>
                    <UploadFileInfoItemText>
                      Need to see this in action first? Download this small
                      <UploadFileInfoDownload onClick={saveExportableFile}> excel sample file </UploadFileInfoDownload>
                      and test the import process so there are no surprises.
                    </UploadFileInfoItemText>
                  </UploadFileInfoItem>
                  <UploadFileInfoItem>
                    <UploadFileInfoItemHeader>Have questions?</UploadFileInfoItemHeader>
                    <UploadFileInfoItemText>
                      Migrating data should be easy. Read the
                      <UploadFileInfoLink href="https://www.ospree.io/ospree/help-desk" target="_blank">
                        &nbsp;answer&nbsp;
                      </UploadFileInfoLink>
                      to all your questions about data security, file, type and troubleshooting.
                    </UploadFileInfoItemText>
                  </UploadFileInfoItem>
                </UploadFileInfoWrapper>
              </>
            )}
          </Dropzone>
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton>
            <Button color="default" onClick={toggleModal}>
              Back
            </Button>
          </ModalFooterButton>
          {caseDataLength < 250 && file ? (
            <ModalFooterButtonSubmit>
              <Button
                color="primary"
                onClick={handleOnSubmit}
                isClose={isUploadCaseModalOpen}
                disabled={caseDataLength > 250 || caseDataLength < 1}
              >
                Submit
              </Button>
            </ModalFooterButtonSubmit>
          ) : (
            <ModalFooterButtonSubmitDis>
              <Button color="primary">Submit</Button>
            </ModalFooterButtonSubmitDis>
          )}
        </ModalFooterWrapper>
      </ModalFooter>
      <ErrorMessageBack>
        {caseDataLength === 0 && <ErrorMessage>File with no records.</ErrorMessage>}
        {caseDataLength > 250 && <ErrorMessage>'Maximum length of rows reached. (max 250)' </ErrorMessage>}
      </ErrorMessageBack>
    </Modal>
  )
}

// Default Props
UploadCaseModal.defaultProps = {
  ui: {},
  actions: {},
  caseId: '',
}

// Proptypes Validation
UploadCaseModal.propTypes = {
  ui: PropTypes.shape({
    isUploadCaseModalOpen: PropTypes.bool,
    activeModule: PropTypes.string,
    activeCase: PropTypes.string,
    activePage: PropTypes.string,
  }),

  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    toggleChooseKYCUploadModal: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
  caseId: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadCaseModal)
