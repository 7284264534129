import styled from 'styled-components'

// Library Components
import { ModalHeader } from 'reactstrap'

export const ModalHeaderWrapper = styled(ModalHeader)`
  border: none;
  display:block;
  text-align: center;
  border-bottom: 1px solid #cbcbcb;
`

export const ModalHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  color:#5f6267;
`

export const ModalHeaderCloseIcon = styled.div`
  cursor: pointer;
`