import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const CasesSubHeaderWrapper = styled.div``

export const CasesSubHeaderNavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
`

export const CasesSubHeaderNavListItem = styled.li`
  padding: 0px;
  margin: 0 10px;
`

export const CasesSubHeaderNavListLink = styled(NavLink)`
  position: relative;
  display: block;
  padding: 8px 0px;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;

  // TODO: Use theme mixins
  &.active {
    font-weight: 700;
  }

  &.active,
  &:hover {
    color: #1104c9;

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #1104c9;
    }
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.gray};
`
