import React, { useEffect,useContext } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Context } from 'common/context/Context';


// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateCase, useFetchAllCaseCountries } from 'core/hooks/api'

// Styled Elements
import { CreateIndividualCaseFormWrapper, FormTitle } from './CreateIndividualCaseForm.elements'

// Views
import { Form, FormTextField, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const CreateIndividualCaseForm = (props) => {
  const {
    updateData,
  } = useContext(Context);
  // Destructure
  const { formRef, form, actions } = props

  // Store State
  const { countryListOptions } = form

  // Store Actions
  const { setCountryListOptions } = actions

  // Variables
  const initialValues = {
    customerId: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    nationality: '',
    countryAddress: '',
  }

  const validationSchema = Yup.object().shape({
    customerId: Yup.string().required('Customer Id is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    dateOfBirth: Yup.string().required('Date of Birth is required'),
    nationality: Yup.string().required('Nationality is required'),
    countryAddress: Yup.string().required('Country Address is required'),
  })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { allCaseCountriesData, getAllCaseCountries } = useFetchAllCaseCountries()
  const { createCase } = useCreateCase()

  // Functions
  const handleOnSubmit = (values) => {
    console.log("test")
    const { customerId, firstName, lastName, dateOfBirth, nationality, countryAddress } = values

    const payload = {
      adverse_media: 0,
      biz_id: userCredentials.Business_ID,
      case_id: '',
      case_risk: 0,
      case_type: 1,
      comments: '',
      company_name: '',
      country_address: countryAddress,
      country_incorporation: '',
      customer_id: customerId,
      date_birth: dateOfBirth,
      date_incoportation: '',
      first_name: firstName,
      last_name: lastName,
      middle_name: '',
      nationality,
      pep: 0,
      sanction: 0,
      status: 1,
    }

    createCase(payload)
    // updateData(true);

  }

  // useEffect
  useEffect(() => {
    if (!countryListOptions.length) {
      getAllCaseCountries()
    }
  }, [])

  useEffect(() => {
    if (!countryListOptions.length && allCaseCountriesData) {
      const countriesList = allCaseCountriesData.map(({ Description, Code }) => ({ value: Code, label: Description }))
      setCountryListOptions(countriesList)
    }
  }, [allCaseCountriesData])

  return (
    <CreateIndividualCaseFormWrapper>
      <FormTitle>What is the Customer ID of the case you want to create?</FormTitle>
      <Form
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormTextField placeholder="Customer ID e.g. ID0001" type="text" name="customerId" />
        <br />
        <FormTextField placeholder="First Name" type="text" name="firstName" />
        <br />
        <FormTextField placeholder="Last Name" type="text" name="lastName" />
        <br />
        <FormTextField placeholder="Date of Birth YYYY-MM-DD" type="date" name="dateOfBirth" />
        <br />
        <FormSelect placeholder="Nationality" name="nationality" options={countryListOptions} />
        <br />
        <FormSelect placeholder="Country Address" name="countryAddress" options={countryListOptions} />
        <br />
      </Form>
    </CreateIndividualCaseFormWrapper>
  )
}

// Default Props
CreateIndividualCaseForm.defaultProps = {
  formRef: {},
  caseCustomerData: {},
  form: {},
  actions: {},
}

// Proptypes Validation
CreateIndividualCaseForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  caseCustomerData: PropTypes.shape({ Customer_ID: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIndividualCaseForm)
