import styled from 'styled-components'

// Library Components
import { Input } from 'reactstrap'

export const SearchbarWrapper = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: center;
  width: 100%;
  & .error {
    border: 1px solid #fd1e4d;
  }
`

export const SearchInputField = styled(Input)`
  padding: 0.7rem 0.75rem;
  height: auto;
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  // TODO: Use theme functions
  &:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
    outline: none;
  }

  // Conditional Styles
  ${({ theme, error }) => error && `border-color: ${theme.colors.red};`}

  &:focus {
    ${({ theme, error }) => error && `border-color: ${theme.colors.red};`}
  }
`

export const SearchButton = styled.button`
  position: absolute;
  right: 2px;
  top: 1px;
  z-index: 5;

  width: 50px;
  height: 45px;

  transition: all 0.3s ease-in-out;

  background-color: #fff;

  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  padding: 0.4rem 0.75rem;

  // TODO: Use theme functions
  &:hover {
    background-color: #f8f9fa;
  }

  // Themed Properties
  border-left: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const SearchIconWrapper = styled.div`
  opacity: 0.5;
  border-style: none;
`
