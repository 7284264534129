import styled from 'styled-components'

// Library Components
import { NavLink } from 'react-router-dom'

export const AddressesTableItemWrapper = styled.div``

export const AccordionTableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AccordionTableButtonItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-weight: 100;
  font-size: 15px;
  font-family: Roboto;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`

export const AccordionTablePanelWrapper = styled.div``

export const ExpandedAccordionTableButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0px 20px;
`

export const CopyIcon = styled.div`
  width: 17px;
  margin-left: 10px;
  visibility: hidden;
`

export const HashWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0px;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 900;
  :hover img {
    visibility: visible;
  }
`

export const CopyToClipboard = styled.div``

export const BlurredHashWrapper = styled.div`
  display: flex;
  font-weight: inherit;
  font-size: inherit;
`

export const DropdownItemInner = styled.div`
  display: flex;
  align-items: center;

  padding: 2px 0px;

  font-weight: 300;
  font-size: 16px;
`

export const DropdownItemInnerText = styled.div`
  margin: 0px 0px 0px 16px;

  font-weight: inherit;
  font-size: inherit;
`

export const UniqueIDLink = styled(NavLink)`
  text-decoration: underline;

  // TODO: Use theme
  color: #1103c8;

  // TODO: Use theme
  &:hover {
    color: #1103c8;
    text-decoration: underline;
  }
`

export const AddressBalanceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
