import { useMutation } from 'react-query'

// Services
import { GetWhitelistingReport } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: getWhitelistingReport,
    data: whitelistingReportData,
    error: whitelistingReportError,
    isLoading: isWhitelistingReportLoading,
    isSuccess: isWhitelistingReportSuccess,
    isError: isWhitelistingReportError,
  } = useMutation(GetWhitelistingReport)

  return {
    getWhitelistingReport,
    whitelistingReportData,
    whitelistingReportError,
    isWhitelistingReportLoading,
    isWhitelistingReportSuccess,
    isWhitelistingReportError,
  }
}
