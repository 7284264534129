import React, { createRef } from 'react'
import PropTypes from 'prop-types'

import { Formik, Form as FormikForm } from 'formik'

// Styled Elements
import { FormWrapper } from './Form.elements'

const Form = (props) => {
  // Destructure
  const { children, initialValues, onSubmit, validationSchema, formRef, ...rest } = props

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        {...rest}
      >
        <FormikForm>{children}</FormikForm>
      </Formik>
    </FormWrapper>
  )
}

// Default Props
Form.defaultProps = {
  children: '',
  initialValues: {},
  onSubmit: () => {},
  validationSchema: {},
  formRef: createRef(),
}

// Proptypes Validation
Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  initialValues: PropTypes.shape({ root: PropTypes.string }),
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({ root: PropTypes.string }),
  formRef: PropTypes.shape({ root: PropTypes.string }),
}

export default Form
