import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

export const AppSidebarDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100%;
  background-color: #282828;
`

export const AppSidebarList = styled.ul`
  padding: 50px 0px;
`

export const AppSidebarListItem = styled.li``

export const AppSidebarListLink = styled(NavLink)`
  display: flex;
  align-items: center;

  margin: 0px 20px 0px 0px;
  padding: 10px 30px;
  font-size: 16px;

  // TODO: Use Theme
  color: #fff;

  // TODO: Use theme functions
  &:hover {
    color: #fff;
  }
`

export const AppSidebarListIcon = styled.div`
  margin: 0px 20px 0px 0px;
`
