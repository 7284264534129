import React from 'react'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Views
import { App, Header, Sidebar, InvestigationChart } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const Investigation = () => (
  <App>
    <Sidebar />
    <Layout>
      <Header />
      <InvestigationChart />
    </Layout>
  </App>
)

// Default Props
Investigation.defaultProps = {}

// Proptypes Validation
Investigation.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(Investigation)
