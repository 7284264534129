import styled from 'styled-components'

export const FormInputWrapper = styled.div``

export const FormLabel = styled.label``

export const FormTextAreaInput = styled.textarea`
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  font-weight: 400;
  outline: none;

  // TODO: Use theme color
  border: 1px solid #ced4da;

  // Conditional Properties
  ${({ resize }) => !resize && 'resize: none;'}
`
