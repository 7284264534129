import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import {
  useFetchDashboardTotalCases,
  useFetchDashboardCaseTransactions,
  useFetchDashboardCaseAddresses,
} from 'core/hooks/api'

// Styled Components
import { CasesWrapper, CaseBreakdownWrapper, CaseBreakdownTableWrapper, CaseBreakdownItem } from './Cases.elements'

// Views
import { Container, DataBreakdownSummary } from 'views/components'
import { App, Header, Sidebar, Content, CaseManagementTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const Cases = (props) => {
  // Destructure
  const { actions } = props

  // Store Actions
  const { setActiveModule, setShowHeaderLoader } = actions

  // States
  const [breakdownSummaryData, setBreakdownSummaryData] = useState()

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    dashboardTotalCasesData,
    isDashboardTotalCasesLoading,
    getDashboardTotalCases,
  } = useFetchDashboardTotalCases()
  const {
    dashboardCaseTransactionsData,
    isDashboardCaseTransactionsLoading,
    getDashboardCaseTransactions,
  } = useFetchDashboardCaseTransactions({})
  const {
    dashboardCaseAddressesData,
    isDashboardCaseAddressesLoading,
    getDashboardCaseAddresses,
  } = useFetchDashboardCaseAddresses({})

  // Functions
  const handleTableChange = (params) => {
    const { caseType, caseStatus } = params

    getDashboardTotalCases({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getDashboardCaseTransactions({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getDashboardCaseAddresses({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
  }

  // useEffect
  useEffect(() => {
    // Set Default Breakdown Summary Data
    setBreakdownSummaryData(() => [dashboardTotalCasesData, dashboardCaseTransactionsData, dashboardCaseAddressesData])

    // Fetch Cases Data on Render
    getDashboardTotalCases({ biz_id: userCredentials.Business_ID })
    getDashboardCaseTransactions({ biz_id: userCredentials.Business_ID })
    getDashboardCaseAddresses({ biz_id: userCredentials.Business_ID })

    // Set Active Module
    setActiveModule('cases')
  }, [])

  useEffect(() => {
    if (dashboardTotalCasesData && dashboardCaseTransactionsData && dashboardCaseAddressesData) {
      setBreakdownSummaryData(() => [
        dashboardTotalCasesData,
        dashboardCaseTransactionsData,
        dashboardCaseAddressesData,
      ])
    }
  }, [dashboardTotalCasesData, dashboardCaseTransactionsData, dashboardCaseAddressesData])

  useEffect(() => {
    if (isDashboardTotalCasesLoading && isDashboardCaseTransactionsLoading && isDashboardCaseAddressesLoading) {
      setShowHeaderLoader(true)
    } else {
      setShowHeaderLoader(false)
    }
  }, [isDashboardTotalCasesLoading, isDashboardCaseTransactionsLoading, isDashboardCaseAddressesLoading])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <CasesWrapper>
            <Container>
              <CaseBreakdownWrapper>
                {breakdownSummaryData &&
                  breakdownSummaryData.map(
                    ({ title, figureTotalValue, figureTitle, figureSubtitle, breakdownData }) => (
                      <CaseBreakdownItem key={uuid()}>
                        <DataBreakdownSummary
                          title={title}
                          figureTotalValue={figureTotalValue}
                          figureTitle={figureTitle}
                          figureSubtitle={figureSubtitle}
                          breakdownData={breakdownData}
                        />
                      </CaseBreakdownItem>
                    )
                  )}
              </CaseBreakdownWrapper>
              <CaseBreakdownTableWrapper>
                <CaseManagementTable onChange={handleTableChange} />
              </CaseBreakdownTableWrapper>
            </Container>
          </CasesWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Cases.defaultProps = {
  actions: {},
}

// Proptypes Validation
Cases.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Cases)
