export const SAMPLE_UPLOAD_FILE = [
  {
    obj: [
      { Address: '0x48AaaE9001F1364A1D0B36E9F0A625cD69155b3D' },
      { Address: '35hK24tcLEWcgNA4JxpvbkNkoAcDGqQPsP' },
      { Address: '3KZ526NxCVXbKwwP66RgM3pte6zW4gY1tD' },
    ],
    header: ['Address'],
    filename: 'ospree_import_address.xlsx',
  },
  {
    obj: [
      {
        'Unique Id': 'ID0001',
        'Entity Type': '1',
        'First Name': 'John',
        'Last Name': 'Smith',
        'Date of Birth': '1945-03-01',
        'Country Location': 'SG',
        Nationality: 'US',
      },
      {
        'Unique Id': 'ID0002',
        'Entity Type': '2',
        'Business Name': 'Company Name',
        'Country Location': 'JP',
        'Registered Country': 'SG',
      },
    ],
    header: [
      'Unique Id',
      'Entity Type',
      'First Name',
      'Last Name',
      'Business Name',
      'Date of Birth',
      'Country Location',
      'Nationality',
      'Registered Country',
    ],
    filename: 'ospree_import_case.xlsx',
  },
  {
    obj: [
      {
        Transaction: '30710defb0de6fc69b168020be5b2ebe88846669e55b96ef8cdc6295187db036',
      },
      {
        Transaction: '3f2b9436ec6f8a94cdf68f461d713d76b78edd9244dfacb284b9bc565956df62',
      },
      {
        Transaction: '0x35b4a3e6e20eecf64df999abff906c1558752fd2d8cef3c6b9e296b8c18258c6',
      },
    ],
    header: ['Transaction'],
    filename: 'ospree_import_transaction.xlsx',
  },
]
