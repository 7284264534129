import styled from 'styled-components'

export const FormInputWrapper = styled.div`
  position: relative;
`

export const FormLabel = styled.label`
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;

  // TODO: Use theme
  color: #393e46;
`

export const FormTextFieldInput = styled.input`
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
  outline: none;
  -webkit-font-smoothing: antialiased;

  // TODO: Use theme color
  background-color: white;
  border: 1px solid #ced4da;

  // Conditional Properties
  ${({ disabled }) => disabled && 'background-color: #f8f9fa'}
`

export const FormErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: red;
  font-size: 14px;
`
