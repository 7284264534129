import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  whitelistingFilters: {},
  caseManagementFilters: {},
}

const store = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setWhitelistingFilters: (state, { payload }) => {
      state.whitelistingFilters = {
        ...state,
        ...payload,
      }
    },
    setCaseManagementFilters: (state, { payload }) => {
      state.caseManagementFilters = {
        ...state.caseManagementFilters,
        ...payload,
      }
    },
  },
})

export default store
