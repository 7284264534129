import { useMutation } from 'react-query'
    // Services
import { SearchWhitelisting } from 'services/api/Whitelisting'
    
  export default () => {
      const {
        mutate: searchWhitelisting,
        data: removewhitelistinglisteAddress,
        error: removewhitelistingReportError,
        isLoading: isWhitelistingReportLoading,
        isSuccess: isWhitelistingReportSuccess,
        isError: isWhitelistingReportError,
      } = useMutation(SearchWhitelisting)
    
    return {
        searchWhitelisting,
        removewhitelistinglisteAddress,
        removewhitelistingReportError,
        isWhitelistingReportLoading,
        isWhitelistingReportSuccess,
        isWhitelistingReportError,
      }
  }