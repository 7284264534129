import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// Styled Elements
import { SelectWrapper, Label, FormGroupWrapper, SelectInput, SelectOption } from './Select.elements'

const Select = (props) => {
  // Destructure
  const { id, label, placeholder, options, onChange, ...rest } = props

  // Variables
  const withOptions = options && options?.length

  // States
  const [value, setValue] = useState()

  const handleOnChange = (e) => {
    onChange(e)
    setValue(e.target.value)
  }

  return (
    <SelectWrapper>
      {label && <Label>{label}</Label>}
      <FormGroupWrapper>
        <SelectInput id={id} type="select" value={value || placeholder} onChange={handleOnChange} {...rest}>
          {placeholder && (
            <SelectOption value={placeholder} disabled hidden>
              {placeholder}
            </SelectOption>
          )}
          {withOptions &&
            options.map(({ value, label }) => (
              <SelectOption key={uuid()} value={value}>
                {label}
              </SelectOption>
            ))}
        </SelectInput>
      </FormGroupWrapper>
    </SelectWrapper>
  )
}

// Default Props
Select.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  options: [],
  onChange: () => {},
}

// Proptypes Validation
Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
}

export default Select
