import styled from 'styled-components'

export const CaseDetailsProfileFormWrapper = styled.div``

export const FormHeader = styled.p`
  margin-bottom: 40px;
  width: 100%;

  font-weight: 700;
  font-size: 17px;
  color: #393e46;
`

export const FormTitle = styled.div`
  margin-bottom: 11px;
  font-size: 17px;
  font-weight: 500;

  // Themed Properties
  color: ${({ theme }) => theme.colors.black};
`

export const FormInputGroupWrapper = styled.div`
  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['block', 'block', 'block', 'block', 'flex'])}
`

export const FormInputGroupItem = styled.div`
  flex: 1;
  padding: 0px 10px 0px 0px;
  margin: 0px 0px 20px 0px;

  // TODO: Use theme function
  &:last-child {
    padding: 0px;
  }

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('padding', ['0px', '0px', '0px', '0px', '0px 10px 0px 0px'])}
`
