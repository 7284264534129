import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Constants
import { Context } from 'common/context/Context';


// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton, ModalCustomeHeader } from './DeleteAddressModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

import { useRemovewhitelistingAddress} from '../../../../core/hooks/api'// Map Redux Props


// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const DeleteAddressModal = (props) => {
  const {removeWhitelistingAddress} = useRemovewhitelistingAddress()
  const {
    updateData,
  } = useContext(Context);
  // Destructure
  const { ui, actions } = props
  const id =props.form.activityToRemove.id
  // Store State 
  const { isDeleteAddressModalOpen } = ui

  // Store Actions
  const { toggleDeleteAddressModal } = actions

  // Functions
  const toggleModal = () => {
    toggleDeleteAddressModal()
  }

  const handleremove = (id) => {
    toggleDeleteAddressModal()
    removeWhitelistingAddress({address_id:id})
    updateData(true);
  }

  return (
    <>
    <Modal isOpen={isDeleteAddressModalOpen} toggle={toggleModal}>
      <ModalCustomeHeader>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete address
      </ModalHeader>
      </ModalCustomeHeader>
      <ModalBody>
        <ModalBodyWrapper>Are you sure you want to delete this address? This cannot be undone.</ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton>
            <Button color="default" onClick={toggleModal}>Cancel</Button>
          </ModalFooterButton>
          <ModalFooterButton>
            <Button color="secondary" onClick={() => handleremove(id)}>Delete</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
    </>
  )
}

// Default Props
DeleteAddressModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
DeleteAddressModal.propTypes = {
  ui: PropTypes.shape({ isDeleteAddressModalOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    toggleDeleteAddressModal: PropTypes.func,
  }),
  id: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddressModal)
