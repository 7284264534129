import { publicAxios } from 'common/utils/axios'
import { authAxios } from 'common/utils/axios'

// Endpoint
import { AUTH_ENDPOINTS } from 'common/constants/apiUrls'

export const Login = async ({ username, password }) => {
  const response = await publicAxios.post(AUTH_ENDPOINTS.LOGIN, {
    username,
    password,
  })

  const data = response.data.content[0]

  return data
}

export const UpdatePassword = async ({ user_id, new_password }) => {
  const response = await authAxios.put(AUTH_ENDPOINTS.RESET_PASSWORD, {
    user_id,
    new_password,
  })

  const data = response.data[0].code

  return data
}
