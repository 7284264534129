import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetMonitoringRiskBucket } from 'services/api/Monitoring'

export default () => {
  const [monitoringRiskBucketData, setMonitoringRiskBucketData] = useState({
    results: {
      highResult: 0,
      mediumResult: 0,
      lowResult: 0,
    },
    chart: {
      labels: ['High', 'Medium', 'Low'],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ['#f5a4a3', '#ffda81', '#9bdbc1'],
          borderWidth: 2,
        },
      ],
    },
    totalValue: 0,
  })

  const {
    mutate: getMonitoringRiskBucket,
    error: monitoringRiskBucketError,
    isLoading: isMonitoringRiskBucketLoading,
    isSuccess: isMonitoringRiskBucketSuccess,
    isError: isMonitoringRiskBucketError,
  } = useMutation(GetMonitoringRiskBucket, {
    onSuccess: (data) => {
      setMonitoringRiskBucketData({
        results: {
          lowResult: data[0].Total,
          mediumResult: data[1].Total,
          highResult: data[2].Total,
        },
        chart: {
          labels: ['High', 'Medium', 'Low'],
          datasets: [
            {
              data: [data[0].Total, data[1].Total, data[2].Total],
              backgroundColor: ['#f5a4a3', '#ffda81', '#9bdbc1'],
              borderWidth: 2,
            },
          ],
        },
        totalValue: data.reduce((accumulator, { Total }) => accumulator + Total, 0),
      })
    },
  })

  return {
    getMonitoringRiskBucket,
    monitoringRiskBucketData,
    monitoringRiskBucketError,
    isMonitoringRiskBucketLoading,
    isMonitoringRiskBucketSuccess,
    isMonitoringRiskBucketError,
  }
}
