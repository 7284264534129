import styled from 'styled-components'

// Library Components
import { Autocomplete } from '@material-ui/lab'

export const FormAutoCompleteWrapper = styled.div``

export const FormLabel = styled.label``

export const FormAutoCompleteInput = styled(Autocomplete)`
  padding: 0px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
`
