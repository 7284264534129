import styled from 'styled-components'

export const CaseManagementTableFilterFormWrapper = styled.div`
  width: 100%;
`

export const FormAutoCompleteWrapper = styled.div`
  width: 100%;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['block', 'block', 'block', 'block', 'block', 'none'])}
`

export const CaseManagementTableFilterInner = styled.div`
  display: flex;
`

export const CaseManagementTableFilterItem = styled.div`
  flex: 1;
  margin: 0px 0px 0px 10px;

  // TODO: Use theme functions
  &:first-of-type {
    flex: 1.2;
    margin: 0px;
  }
`
