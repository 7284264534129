import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'
import PlusIcon from 'assets/images/plus.svg'
import UsersIcon from 'assets/images/users.svg'
import BriefCaseIcon from 'assets/images/briefcase.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  AssignToCaseDrawerWrapper,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerCloseIcon,
  DrawerTitle,
  DropdownItemInnerText,
} from './AssignToCaseDrawer.elements'

// Views
import {
  Drawer,
  Image,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'views/components'

import { AssignToCaseForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const AssignToCaseDrawer = (props) => {
  // Props Destructure
  const { ui, actions } = props

  // Store State
  const { isAssignToCaseDrawerOpen } = ui

  // Store Actions
  const { closeAssignToCaseDrawer, openCreateIndividualCaseDrawer, openCreateEntityCaseDrawer } = actions

  //  Refs
  const formRef = useRef()

  // Functions
  const handleButtonClickSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }

  const handleCloseDrawer = () => {
    closeAssignToCaseDrawer()
  }

  const handleOpenCreateIndividualCaseDrawer = () => {
    openCreateIndividualCaseDrawer()
  }

  const handleOpenCreateEntityCaseDrawer = () => {
    openCreateEntityCaseDrawer()
  }

  return (
    <Drawer open={isAssignToCaseDrawerOpen} anchor="right" maxWidth={['400px']} onClose={() => {}}>
      <AssignToCaseDrawerWrapper>
        <DrawerHeader>
          <DrawerCloseIcon onClick={handleCloseDrawer}>
            <Image width={20} height={20} src={CloseIcon} />
          </DrawerCloseIcon>
          <DrawerTitle>Assign</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <AssignToCaseForm formRef={formRef} />
        </DrawerContent>
        <DrawerFooter>
          <UncontrolledDropdown direction="up">
            <DropdownToggle>
              <Button startIcon={<Image width={16} height={16} src={PlusIcon} />} variant="outlined">
                Create Case
              </Button>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={handleOpenCreateIndividualCaseDrawer}>
                <Image src={UsersIcon} width={16} height={16} />
                <DropdownItemInnerText>Individual Case</DropdownItemInnerText>
              </DropdownItem>
              <DropdownItem onClick={handleOpenCreateEntityCaseDrawer}>
                <Image src={BriefCaseIcon} width={16} height={16} />
                <DropdownItemInnerText>Entity Case</DropdownItemInnerText>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Button onClick={handleButtonClickSubmit}>Assign</Button>
        </DrawerFooter>
      </AssignToCaseDrawerWrapper>
    </Drawer>
  )
}

// Default Props
AssignToCaseDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
AssignToCaseDrawer.propTypes = {
  ui: PropTypes.shape({ isAssignToCaseDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    closeAssignToCaseDrawer: PropTypes.func,
    openCreateIndividualCaseDrawer: PropTypes.func,
    openCreateEntityCaseDrawer: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignToCaseDrawer)
