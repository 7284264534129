import React, { createContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Dates_Calendar } from '../constants/dateFormat'

export const Context = createContext()
export const ContextProvider = ({ children }) => {
  // ALL AVAILABLE GLOBAL VARIABLES

  var base = new Date()
  base.setDate(base.getDate() + 1)
  var end = base.toJSON().slice(0, 10).replace(/-/g, '-')
  base.setDate(base.getDate() - Dates_Calendar.dateRange)
  var start = base.toJSON().slice(0, 10).replace(/-/g, '-')

  const [globalNotifToggler, setGlobalNotifToggler] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const [refreshTableData, setrefreshTableData] = useState(false)
  const [refreshAddress, setRefreshAddress] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: start,
    endDate: end,
  })
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSorBy] = useState('AddressCount')
  const [sortDir, setSortDir] = useState('DESC')

  const updateGlobalNotifToggler = (newState) => {
    setGlobalNotifToggler(newState)
  }

  const updateData = (newState) => {
    setRefreshData(newState)
  }

  const updateAddressData = (newState) => {
    setRefreshAddress(newState)
  }

  const refreshTable = (newState) => {
    setrefreshTableData(newState)
  }

  const updateDateRange = (pickerStartDate, pickerEndDate) => {
    setDateRange({ startDate: pickerStartDate, endDate: pickerEndDate })
  }

  const updatePageIndex = (pageIndexUpdated) => {
    setPageIndex(pageIndexUpdated)
  }

  const updatePageSize = (pageSizeUpdated) => {
    setPageSize(pageSizeUpdated)
  }
  const updateSortBy = (newSortBy) => {
    setSorBy(newSortBy)
  }

  const updateSortDir = (newSortDir) => {
    setSortDir(newSortDir)
  }

  return (
    <Context.Provider
      value={{
        globalNotifToggler,
        refreshData,
        refreshTableData,
        refreshAddress,
        dateRange,
        pageIndex,
        pageSize,
        sortBy,
        sortDir,
        updateGlobalNotifToggler,
        updateData,
        refreshTable,
        updateAddressData,
        updateDateRange,
        updatePageIndex,
        updatePageSize,
        updateSortBy,
        updateSortDir,
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default withRouter(ContextProvider)
