import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: #f8f9fb;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    
    &:hover, &:focus{
      text-decoration: none;
      color: inherit;
    }
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  button {
    &:focus {
      outline: none;
    }
  }

  button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    box-shadow: none;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  label {
    margin-bottom: 5px;
  }
  
  
`

export default GlobalStyle
