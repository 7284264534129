import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

// Library Components
import { TextField } from '@material-ui/core'

// Styled Elements
import { FormAutoCompleteWrapper, FormLabel, FormAutoCompleteInput } from './FormAutoComplete.elements'

const FormAutoComplete = (props) => {
  // Destructure
  const {
    type,
    label,
    options,
    placeholder,
    variant,
    onInputChange,
    getOptionSelected,
    getOptionLabel,
    onSelect,
    ...rest
  } = props

  // Hooks
  const [{ name, onBlur }, meta, { setValue }] = useField(props)

  // Functions
  const handleOnSelect = (e) => {
    const {
      target: { value },
    } = e

    setValue(value)
    onSelect(e)
  }

  return (
    <FormAutoCompleteWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <FormAutoCompleteInput
        type={type}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        name={name}
        onBlur={onBlur}
        onSelect={handleOnSelect}
        onInputChange={onInputChange}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} variant={variant} />}
        {...rest}
      />
    </FormAutoCompleteWrapper>
  )
}

// Default Props
FormAutoComplete.defaultProps = {
  type: '',
  label: '',
  placeholder: '',
  variant: 'outlined',
  options: [],
  onSelect: () => {},
  onInputChange: () => {},
  getOptionSelected: () => {},
  getOptionLabel: () => {},
}

// Proptypes Validation
FormAutoComplete.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
}

export default FormAutoComplete
